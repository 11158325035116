@font-face {
    font-family: mainFont;
    src: url(./assets/fonts/HacenMaghrebLtRegular.ttf);
    font-display: swap;
    font-weight: 200;
}

@font-face {
    font-family: mainFont;
    src: url(./assets/fonts/HacenMaghrebRegular.ttf);
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: mainFont;
    src: url(./assets/fonts/HacenMaghrebBdRegular.ttf);
    font-display: swap;
    font-weight: 600;
}


@font-face {
    font-family: numberFont;
    src: url(./assets/fonts/poppin/Poppins-Light.ttf);
    font-display: swap;
    font-weight: 100;
}

@font-face {
    font-family: numberFont;
    src: url(./assets/fonts/poppin/Poppins-Regular.ttf);
    font-display: swap;
    font-weight: 200;
}


@font-face {
    font-family: numberFont;
    src: url(./assets/fonts/poppin/Poppins-Medium.ttf);
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: numberFont;
    src: url(./assets/fonts/poppin/Poppins-Bold.ttf);
    font-display: swap;
    font-weight: 600;
}

/*#spinner {*/
/*    flex-direction: column;*/
/*    gap: 33px;*/
/*    font-size: 23px;*/
/*}*/

/*#spinner .loading-details {*/
/*    color: #fff;*/
/*    width: 440px;*/
/*    text-align: center;*/
/*}*/

:root {
    --main: #045E5E;
    --main-hover: #083434;
    --red: #990000;
    --red-hover: #6F0000;
}


a {
    text-decoration: none;
    background-color: transparent
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover,
a:hover {
    text-decoration: none !important;
}

button:not(:disabled),
input {
    box-shadow: unset !important;
}


@media (min-width: 1000px) and (max-width: 1200px) {
    .basic-single {
        width: 123px !important;
    }
}


@media (min-width: 1000px) and (max-width: 1400px) {
    .login-modal-h-big-1 {
        height: 100vh !important;
    }
}

input {
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

button:focus {
    border: 1px solid transparent !important;
}

body {
    background: #fff;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: mainFont;
    font-weight: 200;
}

@media screen {

    .slider-module .swiper .swiper-pagination,
    .aq-container {
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (min-width: 900px) {

    .slider-module .swiper .swiper-pagination,
    .aq-container {
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (min-width: 1000px) {
    .w-input {
        width: 240px !important;
    }


    .slider-module .swiper .swiper-pagination,
    .aq-container {
        max-width: 940px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1100px) {

    .slider-module .swiper .swiper-pagination,
    .aq-container {
        max-width: 1040px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1200px) {

    .slider-module .swiper .swiper-pagination,
    .aq-container {
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1300px) {

    .slider-module .swiper .swiper-pagination,
    .aq-container {
        max-width: 1240px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1400px) {


    .slider-module .swiper .swiper-pagination,
    .aq-container {
        max-width: 1340px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1500px) {
    .w-input {
        width: 320px !important;
    }

    .slider-module .swiper .swiper-pagination,
    .aq-container {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1800px) {

    .slider-module .swiper .swiper-pagination,
    .aq-container {
        max-width: 1640px;
        margin-left: auto;
        margin-right: auto;
    }
}

.search-text-box-1.form-control {
    border: 1px solid #DBDBDB;
    border-radius: 9px 0 0 9px !important;
    border-right: 0;
    font-style: normal;
    font-weight: 200;
    color: #727272;
    font-size: 16px;
    line-height: 16px;
    transition: 0s;
}

.search-text-box-1.form-control::placeholder {
    color: #252525;
    font-family: 'mainFont';
    font-weight: 200;
}

.input-group-btn.input-group-btn-22 {
    border-radius: 0 9px 9px 0 !important;
    border: 1px solid #DBDBDB;
    border-left: 0;
}

.input-group-btn.input-group-btn-22 .btn {
    padding-left: 0;
    padding-right: 10px;
    padding-top: 3px;
}

.search-sub-menu .input-group.search-input-group.position-relative {
    padding-right: 5px;
}

.login-container span {
    color: #727272;
    font-size: 16px;
    padding-left: 10px;
}

.mt-4-5 {
    margin-top: 2rem;
}

.pt-4-5 {
    padding-top: 2rem;
}

.aq-header {
    position: relative;
}

a:hover .login-container span {
    color: var(--main-hover);
}

a:hover .login-container svg path,
a:hover .login-container svg circle {
    stroke: var(--main-hover);
    transition: 0.5s;
}

.navbar.navbar-expand-lg.navbar-light .collapse ul li:hover {
    background: var(--main-hover);
}

.aq-header:after {
    content: '';
    width: 100%;
    height: 5px;
    position: absolute;
    display: block;
    z-index: 100;
    background: var(--main);
    top: 0;
}

.social-container a {
    padding: 0 2px;
}

.w-200 {
    width: 245px;
}

.w-100-200 {
    width: calc(100% - 245px);
    padding-right: 35px;
}

.w-200 a .logo.position-absolute {
    width: 245px;
    top: 0;
    z-index: 2;
}

.back-main {
    background: var(--main);
}

.c-pointer {
    cursor: pointer;
}

.child-item {
    position: absolute;
    background: #fff;
    width: fit-content;
    border-radius: 10px;
    margin-top: -5px;
    border-top: 1px solid;
    visibility: hidden;
    transition: 0.3s;
    opacity: 0;
}

.child-item-open {
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
}


.position-relative.nav-item:hover .child-item {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;

}

.position-relative.nav-item:hover .nav-link svg {
    transition: 0.3s;
    transform: rotate(180deg);
}

.nav-item .nav-link svg {
    transition: 0.3s;
}


.pr-14 {
    padding-right: 14px !important;
}

.child-color-a0 .nav-link {
    color: #A0A0A0 !important;
    padding-right: 0;
    padding-left: 0;
}

.child-color-a0 .nav-link:hover {
    color: var(--main) !important;
}

.nav-item .nav-link.text-white.c-pointer.pr-14 {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.image-slider {
    height: calc(100vh - 202px);
    width: 100%;
    object-fit: cover;
}

.slider-module .swiper .swiper-wrapper .swiper-slide .aq-container {
    z-index: 2;
    position: relative;
}

.slider-module .swiper .swiper-wrapper .swiper-slide:after {
    content: '';
    width: 100%;
    position: absolute;
    height: 284px;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgb(0 0 0 / 82%) 28%, rgba(0, 212, 255, 0) 100%);
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.slider-module .swiper .swiper-pagination {
    position: absolute;
    top: calc(100vh - 300px);
    text-align: right;
}

.slider-module .swiper .swiper-pagination {
    right: 0;
}

.slider-container {
    position: absolute;
    bottom: 70px;
    max-width: 50%;
}

.slider-container h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    color: #FFFFFF;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.slider-container p {
    font-weight: 200;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
    color: #D6D6D6;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--main) !important;
}

.swiper-pagination-bullet {
    opacity: 1 !important;
    background: #D9D9D9 !important;
}

.parallax-data {
    display: flex;
    align-items: center;
    gap: 11px;
}

.parallax-data svg {
    width: 50px;
}

.parallax-container-icons.justify-content-center {
    gap: 56px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.parallax-container {
    position: relative;
}

.da {
    font-size: 20px;
    line-height: 23px;
    text-align: right;
    color: #A8A8A8;
}

.counter {
    font-family: numberFont;
    font-weight: 600;
    color: var(--main);
    font-size: 22px;
}

.parallax-data .data-container .disc {
    font-size: 20px;
    color: #727272;
    position: relative;
    top: -5px;
}

.title-container:before {
    content: '';
    display: block;
    position: absolute;
    right: -1px;
    width: 4px;
    top: 0;
    bottom: 20px;
    background: var(--main);
    z-index: 15;
}

.subject-container svg {
    width: 70px;
}

.submitted-answer-container svg {
    width: 60px;
}

.title-container {
    position: relative;
}

.title-container h2 {
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 3px;
    line-height: 30px;
    color: #045E5E;
}

.title-container p {
    font-size: 18px;
    line-height: 20px;
    text-align: right;
    color: #252525;
    margin: 0;
    max-width: 532px;
}


.title-container-2 h2 {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 3px;
    line-height: 30px;
    color: #045E5E;
}

.title-container-2 p {
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #9F9F9F;
    margin: 0;
    max-width: 532px;
}

.parallax-container {
    box-shadow: inset 0 -16px 20px -6px #0000001f;
}

.title-container.mt-5.pe-5:after {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    background: #C0C0C0;
    position: absolute;
    top: 0;
    right: 0;
}

.people-world-container {
    background: var(--main);
}

.question-container-data p {
    color: #F1F1F1;
    font-weight: 400;
    font-size: 20px;
}

.question-container-data small a {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 200;
    line-height: 22px;
}

.question-container-data small a:hover {
    color: #fff;
}

.question-container-data small {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 200;
    line-height: 22px;
}

.red-btn {
    padding: 8px 15px;
    font-size: 15px;
    background: var(--red);
    border-radius: 10px;
    border: 1px solid transparent;
    color: #fff !important;
}

.red-btn:hover {
    background: var(--red-hover);
}

.personal-information {
    padding: 0;
    list-style: none;
    display: flex;
    gap: 25px;
}


.personal-information li {
    color: #313131;
    font-weight: 200;
    font-size: 15px;
}

.cat-link,
.personal-information li a {
    font-size: 15px;
    font-weight: bold;
    text-decoration: underline;
}

.personal-information li img,
.personal-information li svg {
    margin-left: 5px;
}

.personal-information li img {
    border-radius: 8px;
}

.card-question .d-flex .right h3 {
    font-size: 17px;
    color: #535353;
    margin-bottom: 0;
    font-weight: bold;
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-question .d-flex .right a:hover h3 {
    color: var(--main);
}

.number {
    font-family: numberFont;
}

.date {
    font-size: 13px;
    display: inline-block;
    color: #313131;
}

.card-question {
    background: #EEEEEE;
    border-radius: 20px;
    padding: 15px 20px;
}

.more-btn {
    color: #fff !important;
    background: var(--main);
    border-radius: 10px;
    padding: 7px 28px;
    font-size: 16px;
}

.more-btn:hover {
    background: var(--main-hover);
}

.left-line:after {
    content: '';
    display: block;
    width: 2px;
    background: #C0C0C0;
    position: absolute;
    top: 0;
    bottom: 150px;
}

.left-line:before {
    content: '';
    display: block;
    width: 2px;
    height: 150px;
    background: url(./assets/svg/line.svg) no-repeat;
    position: absolute;
    bottom: 0;
    z-index: 1;
}


.left-line-2:after {
    content: '';
    display: block;
    width: 2px;
    background: #C0C0C0;
    position: absolute;
    top: 150px;
    bottom: 150px;
}

.left-line-2:before {
    content: '';
    display: block;
    width: 2px;
    height: 150px;
    background: url(./assets/svg/line.svg) no-repeat;
    position: absolute;
    bottom: 0;
    z-index: 1;
}

.left-line-3:before {
    content: '';
    display: block;
    width: 2px;
    height: 75px;
    background: url(./assets/svg/line.svg) no-repeat;
    position: absolute;
    top: 0;
    z-index: 1;
}

.left-line-3:after {
    content: '';
    display: block;
    width: 2px;
    background: #C0C0C0;
    position: absolute;
    top: 150px;
    bottom: 70px;
}

.left-line-4:before {
    content: '';
    display: block;
    width: 2px;
    height: 75px;
    background: url(./assets/svg/line.svg) no-repeat;
    position: absolute;
    bottom: 0;
    z-index: 1;
}

.left-logo-back {
    background: url(./assets/uploads/left-logo-back.png) no-repeat left top #F7F7F7;
    background-size: 320px;
    background-position-y: 70px;
}

@media screen and (max-width: 1800px) {

    .site-menu li:nth-last-child(2),
    .site-menu li:nth-last-child(3) {
        display: none;
    }

    .site-menu li:nth-last-child(1),
    .site-menu li:nth-last-child(1) .child-item a:nth-child(2),
    .site-menu li:nth-last-child(1) .child-item a:nth-child(1) {
        display: block !important;
    }
}

@media screen and (max-width: 1400px) {
    .site-menu li:nth-last-child(4) {
        display: none;
    }

    .site-menu li:nth-last-child(5) {
        display: none;
    }

    .site-menu li:nth-last-child(1) .child-item a:nth-child(4),
    .site-menu li:nth-last-child(1) .child-item a:nth-child(3) {
        display: block !important;
    }
}

@media screen and (max-width: 1100px) {
    .site-menu li:nth-child(1) {
        display: none;
    }

    .site-menu li:nth-last-child(1) .child-item a:nth-child(5) {
        display: block !important;
    }
}

.swiper-slide {
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: 0 50px !important;
}

.position-relative.nav-item .child-item {
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 18px 20px #00000073;
}

.position-relative.nav-item .child-item a {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    white-space: nowrap;
    display: block;
    padding: 0 18px;
    font-size: 15px;
}

.position-relative.nav-item .child-item a:hover {
    color: var(--main) !important;
}


.book-container .swiper,
.news-container .swiper {
    width: 100%;
    height: 100%;
}

.book-container .swiper .swiper-wrapper .swiper-slide,
.news-container .swiper .swiper-wrapper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 442px;
    border-radius: 15px;
    overflow: hidden;
}

.book-container .swiper .swiper-wrapper .swiper-slide {
    height: 440px;
    display: block;
}

.book-container .swiper .swiper-wrapper .swiper-slide img,
.news-container .swiper .swiper-wrapper .swiper-slide img {
    display: block;
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.book-container .swiper .swiper-wrapper .swiper-slide img {
    height: 345px;
    position: relative;
    top: -17px;
}

.seminar-slider .swiper {
    height: 545px;
    position: relative;
}

.book-container .swiper .swiper-wrapper .swiper-slide {
    width: 220px;
}

.news-container .swiper .swiper-wrapper .swiper-slide {
    width: 308px;
}

.slider-container-news {
    position: absolute;
    bottom: 0;
    padding: 15px 20px 0;
    background: rgba(4, 94, 94, 0.82);
    color: #fff;
    width: 100%;
}

.slider-container-news h3 {
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: #fff;
    margin-bottom: 15px;
}

.slider-container-news p {
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 200;
}

.swiper-container {
    border-radius: 15px;
    overflow: hidden;
}

.gallery-slider-container .swiper .swiper-button-next,
.seminar-slider .swiper .swiper-button-next,
.seminar-slider .swiper .swiper-button-prev,
.user-slider .swiper .swiper-button-next,
.user-slider .swiper .swiper-button-prev,
.books-module .aq-container .swiper .swiper-button-next,
.books-module .aq-container .swiper .swiper-button-prev,
.news-module .aq-container .swiper .swiper-button-next,
.news-module .aq-container .swiper .swiper-button-prev {
    position: absolute;
    bottom: 0;
    top: auto;
    width: 45px;
    height: 45px;
    z-index: 11;
}

.gallery-slider-container .swiper .swiper-button-prev:after,
.seminar-slider .swiper .swiper-button-prev:after,
.user-slider .swiper .swiper-button-prev:after,
.books-module .aq-container .swiper .swiper-button-prev:after,
.news-module .aq-container .swiper .swiper-button-prev:after {
    color: transparent;
    background: url("./assets/svg/owl-right.svg") no-repeat center;
    background-size: 40px;
    width: 45px;
    height: 45px;
}

.gallery-slider-container .swiper .swiper-button-next:after,
.seminar-slider .swiper .swiper-button-next:after,
.user-slider .swiper .swiper-button-next:after,
.books-module .aq-container .swiper .swiper-button-next:after,
.news-module .aq-container .swiper .swiper-button-next:after {
    color: transparent;
    background: url("./assets/svg/owl-left.svg") no-repeat center;
    background-size: 40px;
    width: 45px;
    height: 45px;
}

.gallery-slider-container .swiper .swiper-button-prev:hover:after,
.seminar-slider .swiper .swiper-button-prev:hover:after,
.user-slider .swiper .swiper-button-prev:hover:after,
.books-module .aq-container .swiper .swiper-button-prev:hover:after,
.news-module .aq-container .swiper .swiper-button-prev:hover:after {
    background: url("./assets/svg/owl-right-active.svg") no-repeat center;
    background-size: 40px;
}

.gallery-slider-container .swiper .swiper-button-next:hover:after,
.seminar-slider .swiper .swiper-button-next:hover:after,
.user-slider .swiper .swiper-button-next:hover:after,
.books-module .aq-container .swiper .swiper-button-next:hover:after,
.news-module .aq-container .swiper .swiper-button-next:hover:after {
    background: url("./assets/svg/owl-left-active.svg") no-repeat center;
    background-size: 40px;
}

.gallery-slider-container .swiper .swiper-button-next,
.seminar-slider .swiper .swiper-button-next,
.user-slider .swiper .swiper-button-next,
.books-module .aq-container .swiper .swiper-button-next,
.news-module .aq-container .swiper .swiper-button-next {
    right: 60px;
}

.news-container .swiper {
    height: 525px;
}

.mt--5 {
    margin-top: -2.6rem;
    z-index: 10;
    position: relative;
}

.mt--52 {
    margin-top: -2.6rem;
    z-index: 10;
    left: 0;
    position: absolute;
}

.news-container .swiper {
    margin-top: 1rem;
}

.news-module {
    padding-bottom: 4rem;
    background: #F2F2F2;
}

.date-container-news {
    text-align: right;
}

.date-container-news .date.text-white {
    font-size: 12px;
}

.change-height {
    max-height: 0;
    overflow: hidden;
    transition: 1.3s ease;
}

.swiper-slide:hover .slider-container-news .change-height {
    max-height: 300px;
}

.date-container-news {
    padding-bottom: 15px;
}

.parallax-container .aq-container div div {
    background-size: 100% !important;
}

.book-container .swiper .swiper-wrapper .swiper-slide > div {
    height: 309px;
    margin-bottom: 0;
    overflow: hidden;
    border-radius: 15px;
}

.book-data-container a h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #474747;
    margin-bottom: 4px;
    margin-top: 9px;
    padding: 0 6px;
}

.book-data-container a:hover p,
.book-data-container a:hover h3 {
    color: var(--main);
}

.book-data-container a p {
    font-weight: 200;
    font-size: 14px;
    line-height: 16px;
    color: #737373;
    padding: 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.navi-container-faq .navi,
.navi-container-media .navi,
.btn-navigation-process-container .btn-navigation .navi {
    background: var(--red);
}

.navi {
    position: relative;
    border-radius: 12px;
    font-size: 0;
    z-index: 4;
    height: 52px;
    padding: 10px;
}

.tab-aqua {
    padding: 0;
    background-color: var(--red);
    list-style-type: none;
    color: #fff;
    z-index: 4;
    position: relative;
    border-radius: 10px;
}

.navi {
    position: relative;
    border-radius: 10px;
    font-size: 0;
    z-index: 4;
    height: 52px;
    padding: 10px;
    direction: ltr;
}

.navi-container-media-2 .tab-aqua a,
.navi-container-media-2 .tab-aqua button {
    font-family: mainFont !important;
    font-size: 1.07rem;
}

.navi-container-faq .navi a,
.navi-container-media .navi a,
.btn-navigation-process-container .btn-navigation .navi a,
.navi-container-faq .navi button,
.navi-container-media .navi button,
.btn-navigation-process-container .btn-navigation .navi button {
    color: #fff !important;
}

.navi-container-faq .navi a,
.navi-container-media .navi a,
.navi-container-faq .navi button,
.navi-container-media .navi button {
    font-size: 0.9rem;
    font-family: mainFont !important;
    font-weight: unset;
}

.tab-aqua.navi a,
.tab-aqua.navi button {
    font-size: 1rem;
    font-weight: bolder;
    width: 100px;
    line-height: 14px;
    height: 75%;
    margin-top: -5px;
    display: inline-block;
    background: transparent;
    border: unset !important;
    box-shadow: unset !important;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    z-index: 4;
    border-radius: 10px;
    padding: 12px 8px;
    display: inline-block;
    position: relative;
    font-size: 1rem;
    font-family: mainFont;
    color: #fff;
    padding: 15px;
}

.navi-container-faq .navi a:not(.inactive) ~ .animation,
.navi-container-media .navi a:not(.inactive) ~ .animation,
.btn-navigation-process-container .btn-navigation .navi a:nth-child(1):not(.inactive) ~ .animation,
.btn-navigation-process-container .btn-navigation .navi a:nth-child(2):not(.inactive) ~ .animation,
.navi-container-faq .navi button:not(.inactive) ~ .animation,
.navi-container-media .navi button:not(.inactive) ~ .animation,
.btn-navigation-process-container .btn-navigation .navi button:nth-child(1):not(.inactive) ~ .animation,
.btn-navigation-process-container .btn-navigation .navi button:nth-child(2):not(.inactive) ~ .animation {
    background: var(--red);
    top: 2px;
}

.navi a:nth-child(3):not(.inactive) ~ .animation,
.navi button:nth-child(3):not(.inactive) ~ .animation {
    width: 130px;
    left: 356px;
    background-color: #F0F0F0;
    right: auto;
}

.navi a:nth-child(4),
.navi button:nth-child(4) {
    width: 130px;
}

.navi a:nth-child(4):not(.inactive) ~ .animation,
.navi button:nth-child(4):not(.inactive) ~ .animation {
    width: 135px;
    left: 454px;
    background-color: #F0F0F0;
    right: auto;
}

.navi-container-faq .navi .animation {
    position: absolute;
    height: 65%;
    margin-top: 7px;
    top: 3px;
    z-index: 1;
    transition: all .5s ease 0s;
    border-radius: 10px;
}

.navi .animation {
    position: absolute;
    height: 75%;
    margin-top: 7px;
    top: 0;
    z-index: 1;
    transition: all .5s ease 0s;
    border-radius: 10px;
}

.navi .start-home {
    width: 100px;
    right: 10px;
    background-color: #F0F0F0;
    left: auto;
}

.navi a:nth-child(1):not(.inactive) ~ .animation,
.navi button:nth-child(1):not(.inactive) ~ .animation {
    width: 179px;
    left: 6px;
    background-color: #F0F0F0;
    right: auto;
}

.navi a:nth-child(2):not(.inactive) ~ .animation,
.navi button:nth-child(2):not(.inactive) ~ .animation {
    width: 165px;
    left: 187px;
    background-color: #F0F0F0;
    right: auto;
}

.float-left {
    float: left;
}

.navi-container-faq .navi a:not(.inactive),
.navi-container-faq .navi button:not(.inactive) {
    color: var(--red) !important;
    transition: all .5s ease 0s;
}

.tab-aqua.navi a:nth-child(2),
.tab-aqua.navi a:nth-child(1),
.tab-aqua.navi button:nth-child(2),
.tab-aqua.navi button:nth-child(1) {
    width: 173px;
}


.tab-aqua.navi a:nth-child(3) {
    width: 130px;
}

.books-module {
    padding-bottom: 3.4rem;
}

.guided-module {
    background: url(./assets/image/user-logo.png) no-repeat center #F9F9F9;
    background-size: 200px;
    background-position-x: 0;
    background-position-y: 0;
}

.left-tab {
    padding: 0;
    list-style: none;
}

.left-tab li {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding: 12px 7px 6px;
    cursor: pointer;
    position: relative;
    border-radius: 15px;
}

.left-tab li h3 {
    font-size: 18px;
    line-height: 21px;
    color: #4d4d4d;
    font-weight: bold;
    margin-bottom: 5px;
}

.left-tab li svg {
    margin-left: 15px;
    transform: scale(1.2);
}

.left-tab li.active {
    background: var(--main);
    cursor: default;
}

.left-tab li.active svg circle:not(.active-white) {
    fill: #fff;
}

.left-tab li:not(.active):hover h3 {
    color: var(--main);
}

.left-tab li.active svg circle.active-white {
    stroke: var(--main);
}

.left-tab li:not(:nth-child(3)).active svg path {
    stroke: var(--main);
}

.left-tab li:nth-child(3).active svg path[fill="#EEEEEE"] {
    fill: var(--main);
}

.left-tab li:nth-child(3).active svg path[fill="#045E5E"] {
    fill: white;
}

.left-tab li.active:after {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    background: url(./assets/svg/left-arow-active.svg) no-repeat center;
    background-size: 34px;
    left: 10px;
    top: 5px;
}

.left-tab li:not(.active):hover {
    background: #EEEEEE;
    border-radius: 10px;
}

.left-tab li.active h3 {
    color: #fff;
}

.left-tab li:not(.active) {
    margin-left: 50px;
}

.header-user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid var(--main);
    z-index: 2;
    object-fit: cover;
}

.user-guide {
    width: 257px;
    display: block;
    position: relative;
    margin-top: 73px;
}

.user-guide-w300 {
    width: 300px;
}

.user-desc-container {
    display: block;
    background: transparent;
    padding: 42px 23px 10px;
    border-radius: 10px;
    text-align: center;
    z-index: 0;
    position: relative;
}

.user-name {
    color: #4d4d4d;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-name svg {
    margin-left: 5px;
    width: 22px;
    height: 22px;
}

.user-desc-container-2 div {
    color: #333;
    font-size: 15px;
}

.user-desc-container-2 {
    margin: 12px 0 22px;
    gap: 23px;
}

.header-user-image {
    position: relative;
    top: -73px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.header-user-image-container {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.user-desc-container {
    display: block;
    width: 300px;
    height: 194px;
    background: #eee;
}

.header-user-image-container:after {
    content: '';
    width: 160px;
    height: 100px;
    background: url(./assets/svg/guided-w.svg) no-repeat center;
    z-index: 1;
    position: absolute;
    top: -32px;
}

.user-guide:hover .user-desc-container {
    background: var(--main);
    background-size: 300px;
}

.user-guide .user-desc-container .user-desc-container-2 .last-Religion svg {
    margin-left: 3px;
}

.user-guide:hover .user-desc-container .user-desc-container-2 .last-Religion svg path,
.user-guide:hover .user-desc-container > div.date-container-news.text-center > svg path,
.user-guide:hover .user-desc-container .user-name svg circle,
.user-guide:hover .user-desc-container .user-name svg path {
    stroke: #fff;
}

.user-guide:hover .user-desc-container > div.date-container-news.text-center > div,
.user-guide:hover .user-desc-container > div.date-container-news.text-center > div > span,
.user-guide:hover .user-desc-container .user-desc-container-2 .last-Religion,
.user-guide:hover .user-desc-container .user-desc-container-2 .c-name,
.user-guide:hover .user-desc-container .user-name {
    color: #fff;
}

.user-desc-container-2 div img {
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    border-radius: 8px;
    vertical-align: middle;
    margin-left: 5px;
}

.user-slider .swiper .swiper-wrapper .swiper-slide {
    width: 257px;
}

.user-slider .swiper {
    height: 400px;
}

.seminar-contianer {
    width: 350px;
    display: block;
    overflow: hidden;
    border-radius: 15px;
    background: #fff;
}

.seminar-actor-cotainer img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #fff;
    margin-top: -35px;
    margin-right: 18px;
}

.seminar-image {
    border-radius: 15px 15px 0 0;
    width: 350px;
    height: 240px;
    object-fit: cover;
}

.speaker-container .speaker-name {
    font-size: 15px;
    color: #252525;
    display: block;
}

.speaker-container .speaker-tag {
    font-size: 14px;
    color: #9C9C9C;
    position: relative;
    top: -3px;
}

.speech-name {
    font-size: 18px;
    color: #1a1a1a;
    margin-top: 15px;
    display: block;
    line-height: 1.2;
}

a.speech-name:hover,
.speaker-container .speaker-tag:hover,
.speaker-container .speaker-name:hover {
    color: var(--main);
}

.seminar-slider .swiper .swiper-wrapper .swiper-slide {
    width: 350px;
}

.seminars-module {
    background: #F3F3F3;
    padding-bottom: 60px;
}

.footer-btn-container {
    gap: 20px;
}

.footer-btn-container2 {
    gap: 40px;
}

.footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.footer-column {
    direction: rtl;
    text-align: right;
    font-size: 16px;
    display: flex;
    justify-content: center;
}

.footer-column ul {
    list-style: none;
}

.footer-column ul li h4 {
    white-space: nowrap;
    font-size: 18px;
    color: var(--main);
    margin-bottom: 10px;
}

.footer-column ul li a {
    white-space: nowrap;
    color: #2b2b2b;
    margin-top: 5px;
    display: block;
}

.footer-column ul li a:hover {
    color: var(--main);
}

.tel-mail-container .key {
    font-size: 16px;
    text-align: right;
    color: #2b2b2b;
    font-weight: 800;
}

.tel-mail-container .value {
    font-size: 14px;
    font-family: numberFont;
    text-align: right;
    color: #2b2b2b;
    font-weight: 200;
}

.tel-mail-container:hover .value,
.tel-mail-container:hover .key {
    color: var(--main);
}

.tel-mail-container svg {
    bottom: 3px;
    position: relative;
}

.copy,
.social-media-title {
    font-size: 14px;
    color: #808080;
}

.about-us {
    color: #9A9A9A;
    font-size: 11px;
    font-family: numberFont;
    display: block;
    position: absolute;
    bottom: 20px;
    left: 1px;
    text-align: left;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
}


.about-us:hover svg path {
    transition: 0.1s;
    fill: var(--main);
}

.module-footer {
    background: url(./assets/image/logo/footer.png) no-repeat #EFEFEF;
    background-size: 500px;
    background-position-x: right;
    background-position-y: bottom;
    border-bottom: 6px solid var(--main);
}

.social-container a:hover svg path[fill="#045E5E"] {
    fill: var(--main-hover);
}

.red-link {
    color: var(--red);
    width: 370px;
    text-align: center;
    line-height: 22px;
}

.red-link:hover {
    color: var(--red-hover);
}

.slider-container a:hover h2 {
    color: var(--main);
}

.search-sub-menu .input-group.focus .input-group-btn,
.search-sub-menu .input-group:hover .input-group-btn,
.search-sub-menu .input-group.focus .search-text-box-1,
.search-sub-menu .input-group:hover .search-text-box-1 {
    border-color: var(--main);
}

.search-sub-menu .input-group.focus .input-group-btn button svg path {
    stroke: var(--main-hover);
}

.nav-item .child-item {
    padding: 12px 0;
}

.navbar.navbar-expand-lg.navbar-light {
    padding: 0;
}

.navbar.navbar-expand-lg.navbar-light .collapse ul li {
    padding: 0.5rem 0;
}

.user-slider .worldmap__figure-container svg {
    width: 100%;
}

.card-header-country {
    background-color: var(--red);
    padding: 19px 30px;
    border-radius: 15px 0 0 0;
}

.c-left {
    text-align: left;
    padding-left: 10px;
    color: #fff;
}

.c-left .small-name {
    font-size: 14px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 200;
    margin-bottom: -2px;
    position: relative;
    top: 0;
}

.c-left .number {
    font-size: 14px;
    position: relative;
    top: 1px;
    font-weight: bolder;
}

.c-name {
    cursor: pointer;
}

.c-name span {
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    margin-right: 9px;
    margin-left: 3px;
}

.c-name img {
    border-radius: 4px;
}

.c-body-gray > p {
    padding: 20px 30px;
    font-size: 17px;
    line-height: 26px;
    text-align: justify;
    color: #2e2e2e;
    border-radius: 0 0 0 15px;
    background: #F5F5F5;
    height: 100%;
}

.btn-red a {
    background-color: var(--red);
}

.btn-red a:hover {
    background-color: var(--red-hover);
}

.btn-limit a {
    padding: 3px 26px;
    font-size: 15px;
}

.c-list {
    list-style: none;
    overflow-y: scroll;
    width: 100%;
    padding: 8px 0 100px 0;
    height: 100%;
    background: var(--red-hover);
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    position: relative;
}

.c-list-container:before {
    content: '';
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, rgba(125, 0, 0, 0) 22%, #6f0000 79%);
    position: absolute;
    top: 54px;
    z-index: 555;
    transform: rotate(180deg);
    right: 3px;
}

.c-list-container {
    position: absolute;
    width: 100%;
    bottom: 100%;
    padding: 0;
    border-radius: 0 0 0 15px;
    overflow: hidden;
    margin: 0;
    transition: 0.5s;
    border-radius: 0 0 0 15px;
    background: var(--red-hover);
    top: 0;
    z-index: 1;
}

.c-list li {
    padding: 5px 30px;
    margin: 5px 0;
}

.c-list li span {
    font-weight: 200;
    margin-right: 10px;
}

.c-list li:first-child {
    margin-top: 0 !important;
}

.c-list li:hover {
    background: #B65555;
}

.c-list::-webkit-scrollbar {
    width: 0;
}

.c-list {
    scrollbar-width: none;
}

.c-list-container.open {
    bottom: 0;
    top: 0;
    transition: 0.5s;
}

.c-body-gray {
    position: relative;
    overflow: hidden;
    height: 274px;
}

.form-control-2 {
    background: transparent !important;
    border-color: rgba(255, 255, 255, 0.3) !important;
    font-weight: 200 !important;
}

.btn-search-33,
.form-control-3 {
    background: transparent !important;
    border-color: #9D9D9D !important;
    font-weight: 200 !important;
    color: #9D9D9D !important;
}

.form-control-3::placeholder {
    color: #252525 !important;
    font-size: 14px;
}

.btn-search {
    background: transparent !important;
    border-color: rgba(255, 255, 255, 0.3) !important;
    font-weight: 200 !important;
}

.search-input-group-2 {
    padding: 0 30px !important;
}

.c-list-container:after {
    content: '';
    width: 100%;
    height: 8px;
    background: linear-gradient(180deg, rgba(125, 0, 0, 0) 22%, #6f0000 79%);
    position: absolute;
    bottom: 0;
}

.slider-org-module a {
    border: 2px dashed transparent;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    padding: 10px 30px 10px 10px;
    border-radius: 12px;
    margin-right: 30px;
    transition: 0.5s;
}

.slider-org-module a:hover {
    border-color: var(--red) !important;
}

.map-svg {
    position: absolute;
    right: -35px;
}

.slider-org-module a h3 {
    font-size: 18px;
    color: #343434;
    font-weight: 200;
}

.slider-org-module a .site-link {
    font-size: 16px;
    color: #666666;
    font-family: numberFont;
    font-weight: 400;
}

.slider-org-module a p {
    font-size: 12px;
    color: #838383;
    font-family: numberFont;
    font-weight: 200;
    padding-right: 10px;
}

.slider-org-module a p svg {
    margin-right: 5px;
}

.slider-org-module {
    direction: ltr;

}

.slider-org-module .marquee-container {
    overflow: hidden;
}

.slider-org-module a {
    direction: rtl;
}

.slider-org-module a > span {
    font-size: 25px;
    color: #990000;
}

.slider-org-module a > p > span {
    direction: ltr;
    display: inline-block;
}

.country-module {
    background: url(./assets/image/logo/c-logo.png) no-repeat right bottom;
    background-size: 250px;
}

.parallax-data *,
.parallax-data svg *[fill="#C1C1C1"] {
    transition: 0.3s;
}

.parallax-data:hover svg *[fill="#C1C1C1"] {
    fill: var(--main);
}

.parallax-data:hover svg *[stroke="#C1C1C1"] {
    stroke: var(--main);
}

.parallax-data {
    cursor: pointer;
}

.parallax-data:hover .disc,
.parallax-data:hover * {
    color: var(--main) !important;
}

.hidden-desktop {
    display: none !important;
}

.search-text-box-1.form-control-2 {
    color: rgba(255, 255, 255, 0.8);
}

.fill-op button svg path {
    stroke: rgba(255, 255, 255, 0.8);
}

a .logo.position-absolute.mt-2 {
}

.show-o-container {
    position: absolute;
    right: -55px;
    width: 50px;
}

.back-main-2 {
    background: var(--main-hover);
}

.faq-title {
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}

.faq-desc {
    font-size: 15px !important;
    line-height: 18px !important;
    color: rgba(255, 255, 255, 0.7) !important;
}

.filter-container-mt {
    padding-top: 40px;
    padding-bottom: 40px;
}

.w-input {
    width: 400px;
    padding: 0 !important;
    margin-left: 10px;
}

.select__menu {
    margin: 0 !important;
    border-radius: 0 0 10px 10px !important;
}

.select__control--is-focused,
.css-t3ipsp-control:hover {
    border-color: transparent !important;
    box-shadow: unset !important;
}

.select__control:not(.select__control--menu-is-open) {
    border-radius: 9px;
    background-color: transparent !important;
    border: 1px solid #FFFFFF4D !important;
}

.select__control {
    border-radius: 10px;
    transition: 0s;
}

.select__placeholder,
.select-by-filter p {
    font-size: 14px;
    color: #FFFFFFCC !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.select-by-filter {
    border-radius: 10px;
    background-color: transparent !important;
    border: 1px solid #FFFFFF4D !important;
}

.select__control--menu-is-open {
    border-radius: 10px 10px 0 0 !important;
    transition: 0s;
}

.select__indicator-separator.css-1u9des2-indicatorSeparator {
    display: none;
}

.css-1dimb5e-singleValue {
    color: #FFFFFFCC !important;
    font-size: 13px;
    font-weight: 200;
}

.select__indicators .select__indicato svg {
    cursor: pointer;
}

.select__indicators .select__indicator svg path {
    fill: #fff;
    fill-opacity: 0.8;
}

.select__indicators .select__indicator:hover svg path {
    fill-opacity: 1;
}

.select__control--menu-is-open .css-1dimb5e-singleValue {
    color: hsl(0, 0%, 20%) !important;
}


.select__control--menu-is-open .select__indicators .select__indicator svg path {
    fill: var(--main-hover);
    fill-opacity: 0.8;
}

.select__control--menu-is-open .select__indicators .select__indicator:hover svg path {
    fill-opacity: 1;
}

.select__control--menu-is-open .select__control--menu-is-open .css-1dimb5e-singleValue {
    color: hsl(0, 0%, 20%) !important;
}

.select__option {
    margin: 7px 10px !important;
    width: unset !important;
    border-radius: 12px !important;
    cursor: pointer !important;
    font-size: 13px !important;
}

.select__menu-list {
    padding: 5px 0 !important;
}

.css-d7l1ni-option:active,
.select__option.select__option--is-focused {
    background-color: var(--main) !important;
    color: #fff;
    border-radius: 9px !important;
}

.select__value-container {
    padding: 10px 10px 5px;
}

.select__control {
    border: #fff !important;
    box-shadow: unset !important;
}

.select__menu {
    box-shadow: 0 1px 2px 0px #00000069 !important;
    scrollbar-width: none;
}

.select__menu::-webkit-scrollbar {
    width: 0 !important;
}

.basic-single {
    position: relative;
    width: 146px !important;
    z-index: 5;
}

.search-sub-menu {
    display: flex;
    gap: 15px;
    width: fit-content;
}

.select__control--menu-is-open .select__value-container .select__placeholder {
    color: var(--main) !important;
    font-weight: 400;
}

.select__value-container {
    padding: 5px 15px !important;
}

.color-white {
    background-color: rgba(255, 255, 255, 0.9);
    color: var(--main) !important;
}

.search-result {
    color: #9B9B9B;
    font-size: 14px;
    font-weight: 200;
}

.search-result b {
    color: #5B5B5B;
    font-family: numberFont;
    font-weight: bold;
}

.search-result span:not(.number) {
    color: #5B5B5B !important;
    font-weight: 400 !important;
}

.search-result span.number {
    border-color: #5B5B5B !important;
    font-family: numberFont;
    font-weight: bold !important;
}

.guided-title h2 {
    font-size: 18px !important;
    color: #5B5B5B;
    line-height: 18px;
}

.list-color {
    list-style: none;
    padding: 0 27px 0 0;
    margin-bottom: 0;
}

.list-color li::before {
    content: "\2022";
    color: var(--red);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    font-size: 20px;
    position: absolute;
    top: -5px;
    margin-right: -1em;
}

.list-color li {
    font-size: 15px !important;
    color: #474747;
    position: relative;
}

.list-color li.red {
    color: var(--red);
    font-weight: 400;
}

.list-color li:not(.red) {
    color: #474747;
}

.border-dashed-after {
    border: 2px dashed var(--red);
    padding: 30px 0 30px;
    border-radius: 20px;
    margin-bottom: 45px;
    margin-top: 15px;
}

.d-flex .sec-1 {
    text-align: center;
}

.d-flex .sec-1 span {
    display: block;
}

.d-flex .sec-1 span:first-child {
    font-family: numberFont;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
}

.d-flex .sec-1 span:last-child {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.80);
}

.detail-container {
    background: var(--red);
    height: fit-content;
    padding: 15px 25px;
    border-radius: 17px;
}

.back-f1 {
    background: #f1f1f1;
}

.subject-container-scroll,
.bg-white-container {
    height: 100%;
}

.subject-container-scroll {
    height: calc(100% - 65px);
}

.subject-container-scroll div::-webkit-scrollbar {
    width: 0;
}

.subject-container-scroll div {
    scrollbar-width: none;
}

.subject-container-scroll .content a {
    display: block;
    background: transparent;
    color: #7C7C7C;
    margin: 10px 0;
    padding: 7px 10px !important;
    border-radius: 12px;
    font-size: 16px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.subject-container-scroll .content p {
    color: #7C7C7C;
    font-size: 16px;
    font-weight: 400;
    padding-top: 20px;
    padding-right: 10px;
    margin-bottom: 5px;
}

.subject-container-scroll .content ul {
    margin: 0;
    list-style: none;
    padding: 0;
}

.subject-container-scroll .content ul li {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
    margin-top: 5px;
    color: #252525;
}

.subject-container-scroll .content ul li.select,
.subject-container-scroll .content ul li:hover {
    background-color: var(--main);
    color: #fff;
}

.subject-container-scroll .content a:not(.select):hover,
.subject-container-scroll .content a.select {
    background: var(--main);
    color: #fff;
}

.subject-container-scroll div div {
    margin: 0 !important;
}

.subject-container-scroll div .content {
    padding-right: 10px;

}

.subject-container .search-filter {
    width: 100%;
    font-size: 14px;
    color: #B6B6B6;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    font-weight: 200;
    padding: 5px 10px;
    margin-top: 20px;
}

.subject-container h3 {
    color: var(--main);
    font-size: 18px;

}

.bg-white-container {
    background: #fff;
    padding: 19px;
    border-radius: 15px;
}

.btn-not-fill {
    color: #424242;
    font-size: 16px;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid #CFCFCF;
    border-radius: 8px;
    padding: 7px 10px;
}

.mt-3-4 {
    margin-top: 1.43rem !important;
}

.book-container-inline {
    width: 200px;
    display: inline-block;
}

.book-container-inline .book-image-container img {
    width: 100%;
    border-radius: 20px;
}

.btn-not-fill:not(.selected):hover {
    color: #045E5E;
    background: rgba(4, 94, 94, 0.15);
    border-color: transparent !important;
}

.btn-not-fill.select {
    background: var(--main);
    border-color: transparent;
    color: #fff;
}

.bg-def-g {
    background: #F4F4F4;
}

.login-is-rew h2 {
    font-size: 20px;
    color: #fff;
}

.login-is-rew small {
    font-size: 18px;
    color: rgb(255 255 255 / 80%);
}

.green-box {
    background: var(--main);
    padding: 20px;
}

.f-15 {
    font-size: 15px;
}

.color-90 {
    color: #FFFFFFE5;
}

.form-container > div {
    margin-top: 40px;
}

.form-container div input {
    border: none !important;
    background: transparent;
    border-bottom: 1px solid #FFFFFF33 !important;
    font-size: 14px;
    font-weight: 200;
    color: #FFFFFF99 !important;
    padding-bottom: 5px;
}

.form-container div input::placeholder {
    color: #FFFFFF99 !important;
}

.radio-label > input[type="radio"] {
    display: none;
}

.form-container div label span {
    display: block;
    color: #FFFFFF99 !important;
    font-size: 0.9rem;
    cursor: pointer;
    margin-left: 50px;
    font-family: mainFont;
    display: inline-block;
    text-align: right;
}

.radio-label > input[type="radio"] + *::before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 1rem;
    height: 1rem;
    margin-left: 0.3rem;
    border-radius: 50%;
    top: -4px;
    border-style: solid;
    border-width: 0.12rem;
    border-color: rgba(255, 255, 255, 0.8);
    position: relative;
    background: var(--main);
}

.radio-label > input[type="radio"]:checked + *::before {
    background: var(--main);
    border-color: rgba(255, 255, 255, 0.8);
    box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.8);
}

@keyframes pull {
    0% {
        height: 0;
    }

    100% {
        height: 1.2rem;
    }
}

@keyframes pop {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2);
    }
}

.checkbox-flip,
.checkbox-pop,
.checkbox-pull,
.checkbox-spin {
    display: none;
}

.checkbox-flip + label span,
.checkbox-pop + label span,
.checkbox-pull + label span,
.checkbox-spin + label span {
    display: inline-block;
    width: 25px;
    height: 19px;
    margin: 0 0 -6px 0;
    /*layout relationship between check and label*/
}

.checkbox-flip + label span:before,
.checkbox-flip + label span:after,
.checkbox-pop + label span:before,
.checkbox-pop + label span:after,
.checkbox-pull + label span:before,
.checkbox-pull + label span:after,
.checkbox-spin + label span:before,
.checkbox-spin + label span:after {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    content: "";
    position: absolute;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 3px;
}

.checkbox-flip + label span:after,
.checkbox-pop + label span:after,
.checkbox-pull + label span:after,
.checkbox-spin + label span:after {
    z-index: 0;
    border: none;
}

.checkbox-flip:checked + label span:before {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    background: #FF4040;
}

.checkbox-pop:checked + label span:before {
    animation: pop 0.3s ease;
    z-index: 100;
    background: url(./assets/svg/icon-check.svg) no-repeat center var(--main-hover);
    background-size: 14px;
}

.checkbox-pull:checked + label span:after {
    width: 1.2rem;
    height: 1.2rem;
    background: #FF4040;
    animation: pull 0.4s linear;
}

.checkbox-spin:checked + label span:before {
    -webkit-transform: rotateZ(180deg);
    -moz-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
    background: #FF4040;
}

.color-label-ff {
    color: #FFFFFF99 !important;
    font-size: 0.9rem;
    font-family: mainFont !important;
}

.color-label-f2 {
    font-size: 0.9rem;
    font-family: mainFont !important;
}

.color-label-ff {
    position: relative;
    top: 1px;
}

.user-guide-container .col-3 {
    margin-top: 20px;
}

.user-name-container {
    display: flex;
    align-items: center;
}


.news-container .swiper .swiper-wrapper .swiper-slide a {
    width: 100%
}

.like-and-bookmark {
    margin-top: -32px;
}

.w-34 {
    width: 34px;
    position: relative;
    left: 1px;
}

.text-page-title {
    font-size: 18px;
    color: #4f4f4f;
    margin-bottom: 0;
    padding-top: 4px;

}

.number-view {
    font-family: numberFont;
    font-size: 14px;
    color: #757575;
    font-weight: 400;
}

.cat-link {
    font-weight: bold;
    margin-top: 10px;
}

.cat-link svg {
    margin-left: 5px;
    margin-right: -3px;
}

.cat-link:hover svg rect,
.cat-link:hover svg path {
    stroke: var(--main);
}

.mt-1-5 {
    margin-top: 0.30rem;
}

.mt--1-5 {
    margin-top: -0.30rem;
}

.mt--2-5 {
    margin-top: -0.50rem;
}

.text-page-disc {
    font-weight: 200;
    font-size: 16px;
    color: #636363;
    line-height: 28px;
    text-align: justify;
    margin-top: 8px;
}

.f-13 {
    font-size: 13px;
}

.cat-link-f14 {
    font-size: 15px;
}

.footnote {
    padding: 25px;
    border: 2px dashed #C8C8C8;
    border-radius: 20px;
    text-align: justify;
}

.go-to-left {
    font-size: 14px;
    position: absolute;
    font-weight: bold;
    right: 0;
    transform: translateX(100%);
    color: var(--main);
}

.green-border:after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    background: var(--main);
    right: 27px;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.green-border-22:after {
    top: 1px !important;
}

.w-165 {
    width: 165px;
}

.btn-not-fill-13 .btn-not-fill {
    font-size: 14px !important;
}

.border-red-and-dashed div,
.border-red-and-dashed {
    height: 150px;
}

.border-red-and-dashed-2 div,
.border-red-and-dashed-2 {
    height: 70px;
}

.border-red-and-dashed .col-12:after,
.border-red-and-dashed .col-10:after,
.border-red-and-dashed .col-8:after,
.border-red-and-dashed .col-7:after,
.border-red-and-dashed .col-6:after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    background: url(./assets/svg/line-dash-red.svg);
    right: 27px;
    top: 0;
    bottom: 0;
    z-index: -2;
}

.color-red {
    color: var(--red);
}

.btn-show-answer {
    padding: 5px 19px;
    background: #D2D2D2;
    border-radius: 10px;
    color: #767676;
    box-shadow: unset !important;
    font-size: 16px;
    font-weight: 200;
    border: 1px solid transparent !important;
    transition: 0.3s;
}

.green-border.red-b:after {
    background: var(--red);
}

.main-indent .green-border .user-name-container {
    position: relative;
}

.main-indent .green-border .user-name-container:before {
    content: '';
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    background: url('./assets/svg/corner-down-right.svg') no-repeat center;
    background-size: 25px;
    top: -5px;
    right: 8px;
}

.f-12-red {
    font-size: 14px;
    color: var(--red);
    font-weight: bold;
}

.send-answer {
    background: var(--main);
    color: #fff;
}

.btn-show-answer-w {
    background: #fff;
    color: var(--main);
}

.f-12 {
    font-size: 16px;
    color: #ffffffde;
}

.green-border.white-b:after {
    background: url(./assets/svg/line-dash-white.svg);
    z-index: 1;
}

.border-green-and-dashed div:after {
    background: url(./assets/svg/line-dash-green.svg) !important;
}

.border-green-and-dashed div,
.border-green-and-dashed {
    height: 100px;
}

.image-container img {
    width: 170px;
    height: 170px;
    border-radius: 100%;
    border: 12px solid #EFEFEF;
    object-fit: cover;
    margin-top: 30px;
    background: #f7f7f7;
}

.f-16 {
    font-size: 18px;
    font-weight: 200;
    margin: 0;
}

.books-module-2 .swiper .swiper-wrapper .swiper-slide {
    background: transparent;
    width: 157px;
    height: 340px;
}

.books-module-2 .swiper .swiper-wrapper .swiper-slide img {
    height: 245px;
}

.books-module-2 .swiper .swiper-wrapper .swiper-slide > div {
    height: 209px;
}

.books-module-2 .swiper .swiper-wrapper .swiper-slide > div.only-home-page {
    height: 355px;
}

div.only-home-page .book-image-container a img {
    position: unset !important;
    border-radius: 17px;
    height: 245px !important;
}

.book-data-container-2 a h3 {
    color: #fff !important;
}

.book-data-container-2 a p {
    color: rgba(255, 255, 255, 0.5) !important;
}

.books-module-2 .aq-container .swiper .swiper-button-next:after {
    background: url(./assets/svg/owl-left-white.svg) no-repeat center;
    background-size: 40px;
}

.books-module-2 .aq-container .swiper .swiper-button-next:hover:after {
    background: url(./assets/svg/owl-left-active-2.svg) no-repeat center;
    background-size: 40px;
}

.books-module-2 .aq-container .swiper .swiper-button-prev:after {
    background: url(./assets/svg/owl-right-white.svg) no-repeat center;
    background-size: 40px;
}

.books-module-2 .aq-container .swiper .swiper-button-prev:hover:after {
    background: url(./assets/svg/owl-right-active-2.svg) no-repeat center;
    background-size: 40px;
}

.bg-white-btn {
    background: #FFFFFFF2;
    color: #045E5E !important;
}

.bg-white-btn:hover {
    color: #fff !important;
}

.user-name-container-line-white:after {
    content: '';
    background: #fff;
    position: absolute;
    width: 2px;
    top: 50px;
    bottom: 50px;
    right: 27px;
}


.user-slider-2 .swiper {
    height: 344px;
}

.book-data-container-2 a:hover h3 {
    color: var(--main-hover) !important;
}

.book-data-container-2 a:hover p {
    color: var(--main-hover) !important;
}


.border-green-and-not-dashed div:after {
    background: var(--main) !important;
}

.border-green-and-not-dashed div,
.border-green-and-not-dashed {
    height: 50px;
}

.after-t-0.green-border .user-name-container-line-white:after {
    top: 0 !important;
}

.seminar-contianer.seminar-container-w {
    background: #FAFAFA;
    min-height: 163px;
}

.image-text-page-container img,
.image-text-page-container video {
    border-radius: 20px;
    width: 100%;
    height: 418px;
    object-fit: cover;
}

.w-logo-container {
    width: 50px;
    height: 50px;
    display: block;
    position: relative;
    border-radius: 50%;
    left: 8px;
    object-fit: cover;
    border: 2px solid var(--main);
}

.go-to-left.color-main {
    color: var(--main);
    margin-top: -25px;
    margin-right: -7px;
}

.go-to-left.color-r {
    color: var(--red);
    margin-top: 25px;
    margin-right: -7px;
}

.go-to-left.color-r * {
    color: var(--red);
    font-size: 12px;
}

.go-to-left.color-r svg {
    width: 18px;
}

.weight-normal {
    font-weight: normal !important;
}

.speaker-name-title {
    color: #808080;
    font-size: 15px;
}

.color-main-2 {
    color: var(--main);
}

.border-green-and-dashed-2 {
    height: 50px !important;
}

.btn-not-fill-tag {
    font-size: 14px;
    color: #7C7C7C !important;
    border: 1px solid #7C7C7C;
    background: #fff;
    padding: 5px 18px;
}

.btn-not-fill-tag:hover {
    color: #fff !important;
    background: var(--main) !important;
    border-color: var(--main) !important;
}

.more-btn.d-inline-block.ms-2.btn-not-fill-tag-4 {
    padding-right: 9px;
    padding-left: 11px;
}

.btn-not-fill-tag-4:hover svg * {
    stroke: #fff;
}

.send-comment {
    background: #F6F6F6;
}

.green-border-z-2:after {
    z-index: 1 !important;
}

.color-767676 {
    color: #767676;
}

.form-container-c-black div textarea,
.form-container-c-black div input {
    border: none !important;
    background: transparent;
    border-bottom: 1px solid #DADADA !important;
    font-size: 14px;
    font-weight: 200;
    color: #252525 !important;
    padding-bottom: 5px;
}

.form-container div textarea {
    resize: none;
    outline: none;
    height: 30px !important;
}

.form-container div textarea::-webkit-scrollbar {
    width: 0;
}

.form-container div textarea {
    scrollbar-width: none;
}

.form-container-c-black div input::placeholder {
    color: #252525 !important;
}


.form-container-c-black div input.pass-input,
.form-container-c-black div input[type="password"],
.form-container-c-black div input[type="email"] {
    text-align: left;
    direction: ltr;
}

.form-container-c-black div input[type="password"]::placeholder,
.form-container-c-black div input[type="email"]::placeholder {
    text-align: right;
    direction: rtl;
}

.color-main-3 {
    color: var(--main);
    font-size: 12px;
}

.color-main-3 b {
    color: var(--main);
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
}

.mt-4-1-5 {
    margin-top: -1.8rem;
}

.fs-12-r {
    font-size: 15px;
    padding-top: 2px;
    color: var(--main);
    font-weight: bold;
}

.fs-12-l {
    font-size: 13px !important;
}

.comment-body {
    font-weight: 200;
    font-size: 15px;
    line-height: 25px;
    text-align: justify;
    color: #9B9B9B;
}

.and-dislike {
    font-size: 16px;
    color: #4E4E4E;
    margin-right: 5px;
    font-family: numberFont;
    position: relative;
    top: 3px;
}

.open-answer-form {
    color: var(--main) !important;
    font-weight: 200;
    font-size: 16px;
}

.open-answer-form:hover {
    color: var(--main-hover) !important;
}

.max-h-0 {
    max-height: 0;
    overflow: hidden;
    transition: 1s ease-in-out;
}

.max-h-100 {
    max-height: 1000px;
    overflow: hidden;
    transition: 1s ease-in-out;
}

.answer-form-container {
    background: var(--main);
    padding: 10px 40px 30px 40px;
    border-radius: 20px;
    margin-top: 10px;
    position: relative;
}

.btn-w {
    background: #fff;
    color: var(--main) !important;
}

.btn-w:hover {
    background: var(--main-hover);
    color: #fff !important;
}

.answer-form-container div textarea,
.answer-form-container div input {
    color: rgba(255, 255, 255, 0.8) !important;
    border-color: rgba(255, 255, 255, 0.2) !important;
}


.answer-form-container div textarea::placeholder,
.answer-form-container div input::placeholder {
    color: rgba(255, 255, 255, 0.8) !important;
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.answer-container-pad-right {
    padding-right: 60px;
    position: relative;
}

.answer-container-pad-right:before {
    content: '';
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    background: url('./assets/svg/corner-down-right.svg') no-repeat center;
    background-size: 25px;
    top: 13px;
    right: 11px;
}

.bg-main {
    background: #EEEEEE;
    padding: 25px 30px;
    border-radius: 20px;
}

.comment-body-2 {
    font-weight: 200;
    font-size: 14px;
    text-align: justify;
    color: #787878;
    line-height: 24px;
    margin-bottom: 0 !important;
}

.color-767676-2 {
    color: #78787899 !important;
    font-size: 14px;
    padding-top: 2px;
    font-weight: 200 !important;
}

.border-green-and-dashed-c2 div,
.border-green-and-dashed-c2 {
    height: 70px !important;
}

.border-green-and-dashed-c2 .col-7:after {
    z-index: 1;
}

.on-hover:hover .change-height {
    max-height: 300px;
}


.on-hover img {
    width: 260px;
    height: 355px;
    object-fit: cover;
}

.on-hover {
    display: block;
    width: 260px;
    height: 355px;
    border-radius: 20px;
    overflow: hidden;
}

.color-ABA {
    color: #636363;
}

.gallery-slider {
    width: 278px;
    height: 278px;
    display: block;
    overflow: hidden;
    border-radius: 15px;
}

.gallery-slider img {
    width: 278px;
    height: 278px;
    object-fit: cover;
}

.gallery-slider-container,
.gallery-slider-container .swiper .swiper-wrapper {
    width: 100%;
    height: 350px;
}

.gallery-slider-container .swiper .swiper-wrapper .swiper-slide {
    width: 278px;
    height: 278px;
}


.gallery-slider-container .swiper .swiper-button-next {
    position: absolute;
    bottom: 0;
    top: auto;
    width: 45px;
    height: 45px;
    z-index: 11;
}

.gallery-slider-container .swiper .swiper-button-prev:after {
    color: transparent;
    background: url("./assets/svg/owl-right.svg") no-repeat center;
    background-size: 40px;
    width: 45px;
    height: 45px;
}

.gallery-slider-container .swiper .swiper-button-next:after {
    color: transparent;
    background: url("./assets/svg/owl-left.svg") no-repeat center;
    background-size: 40px;
    width: 45px;
    height: 45px;
}

.gallery-slider-container .swiper .swiper-button-prev:hover:after {
    background: url("./assets/svg/owl-right-active.svg") no-repeat center;
    background-size: 40px;
}

.gallery-slider-container .swiper .swiper-button-next:hover:after {
    background: url("./assets/svg/owl-left-active.svg") no-repeat center;
    background-size: 40px;
}

.gallery-slider-container .swiper .swiper-button-next {
    right: auto;
    left: 0;
}

.gallery-slider-container .swiper .swiper-button-prev {
    left: 50px;
    right: auto;
}

.gallery-slider-container .swiper .swiper-button-prev {
    position: absolute;
    bottom: 0;
    top: auto;
    width: 45px;
    height: 45px;
    z-index: 11;
}

.video-play-icon {
    position: absolute;
    z-index: 5;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.fslightbox-slide-number-container {
    display: none;
}

.book-image-container-c-2 img,
.book-image-container-c-2 {
    width: 100%;
    height: 270px;
}

.book-image-container-c-2 img {
    object-fit: cover;
    border-radius: 15px;
}

.book-data-container-w-2 {
    max-width: 100%;
}

.book-in-page {
    width: 180px;
    height: 240px;
    display: inline-block;
    object-fit: cover;
    border-radius: 12px;
}

.width-fit {
    width: fit-content;
}

.key {
    font-size: 16px;
    line-height: 27px;
    text-align: right;
    color: #949494;
    font-weight: 200;
}

.value a,
.value {
    font-size: 16px;
    line-height: 27px;
    text-align: right;
    color: #737373;
    font-weight: bold;
}

.value {
    padding-right: 20px;
}

.value a:hover {
    color: var(--main);
}

.t-1 {
    top: 1px;
}

.books-module-2 .book-container-green-nav .swiper .swiper-button-next:after {
    background: url(./assets/svg/owl-left.svg) no-repeat center;
    background-size: 40px;
}

.books-module-2 .book-container-green-nav .swiper .swiper-button-prev {
    left: 50px;
    right: auto;
}

.books-module-2 .book-container-green-nav .swiper .swiper-button-next {
    left: 0;
    right: auto;
}

.books-module-2 .book-container-green-nav .swiper .swiper-button-prev:after {
    background: url(./assets/svg/owl-right.svg) no-repeat center;
    background-size: 40px;
}

.books-module-2 .swiper .swiper-wrapper .swiper-slide {
    background: transparent;
    width: 180px;
    height: 390px;
}

.books-module-2 .swiper .swiper-wrapper .swiper-slide img {
    height: 270px;
}

.books-module-2 .swiper .swiper-wrapper .swiper-slide > div {
    height: 250px;
}

img.interviews {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: calc(100% - 51px);
    object-fit: cover;
    border-radius: 10px;
}

.container-to-top {
    margin-top: -50px;
    z-index: -1;
    position: relative;
    left: 0;
    right: 0;
    top: -11px;
    padding: 15px;
    background-color: #eee;
    border-radius: 0 0 10px 10px;
}

a:hover .container-to-top {
    background-color: var(--main);
    color: #fff;
}

a:hover .container-to-top * {
    color: #fff;
    stroke: #fff;
}

.back-transparent {
    background-color: transparent !important;
}

.user-desc-container.back-transparent {
    height: 230px;
}

.overlay-play {
    background: url(./assets/svg/play.svg) no-repeat center;
    background-size: 40px;
    width: 40px;
    height: 40px;
    display: block;
    z-index: 4;
    position: absolute;
    left: 0;
    right: 50%;
    margin: 0 !important;
    transform: translate(50%, -50%);
    top: 96px;
}


.form-container-2 div label span {
    display: block;
    color: #FFFFFF99 !important;
    font-size: 0.9rem;
    cursor: pointer;
    margin-left: 50px;
    font-family: mainFont;
    display: inline-block;
}

.form-container-2 div {
    margin-top: 25px;
}

.form-container-2 div input {
    border: none !important;
    background: transparent;
    border-bottom: 1px solid #E2E2E2 !important;
    font-size: 14px;
    font-weight: 200;
    color: #838383 !important;
    padding-bottom: 5px;
}

.form-container-2 div input::placeholder {
    color: #252525 !important;
}

.user-name-container-line-white-b:after {
    content: '';
    background: #fff;
    position: absolute;
    width: 2px;
    top: 50px;
    bottom: 50px;
    right: 27px;
    z-index: 1;
}

.text-white-80 {
    color: rgb(255 255 255 / 90%) !important;
    font-weight: 400;
}

.text-white-90 {
    color: #FFFFFFE6;
}

.text-white-60 {
    color: #FFFFFF99 !important;
    direction: ltr;
    font-weight: lighter !important;
    font-family: numberFont;
    font-size: 14px !important;
    cursor: pointer;
}

.text-white-60:hover {
    color: #FFFFFF !important;
}

.value.text-white-60 {
    padding-right: 60px !important;
}

.book-value-container-3 tbody tr th,
.book-value-container-3 tbody tr td {
    padding-top: 15px;
}

.book-value-container-3 tbody tr th svg {
    width: 20px;
    position: relative;
    top: -2px;
    margin-left: 5px;
}

.after-b-0:after {
    bottom: 0 !important;
}

.social-container-ul {
    list-style: none;
    padding: 0;
}

.social-container-ul li,
.social-container-ul li a {
    color: rgba(255, 255, 255, 0.8);
    direction: ltr;
    display: inline-block;
    margin-left: 3px;
}

.social-container-ul li a svg {
    margin-right: 5px;
}

.social-container-ul li a:hover svg path {
    fill-opacity: 1;
}

.social-container-ul li a:hover {
    color: #fff;
}

.w-unset {
    width: unset !important;
}

.w-400 {
    width: 425px;
}

.search-filter-res {
    font-size: 16px;
    line-height: 33px;
    color: #7D7D7D;
}

.social-container-ul-filter {
    padding: 0;
    list-style: none;
}

.social-container-ul-filter li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.social-container-ul-filter li button .number-bold {
    font-family: numberFont;
    font-size: 18px;
    line-height: 33px;
    text-align: right;
    color: #868686;
    font-weight: bold;
    padding-top: 10px;
    display: inline-block;
}

.social-container-filter,
.social-container-filter svg {
    width: 80px;
    height: 80px;
}

.social-container-ul-filter li button.selected .number-bold,
.social-container-ul-filter li button:hover .number-bold {
    color: var(--main);
}

.social-container-ul-filter li button:hover div svg path[fill="#F4F4F4"] {
    fill: rgba(4, 94, 94, 0.15);
}

.social-container-ul-filter li button.selected div svg path[fill="#F4F4F4"] {
    fill: var(--main);
}

.social-container-ul-filter li button.selected div svg path[fill="#868686"] {
    fill: #fff;
    stroke: #fff;
}

.subject-container-bg {
    background: #F4F4F4;
}

.title-green {
    font-size: 22px;
    line-height: 25px;
    color: #045E5E;
}

.btn-not-fill-custom {
    background: transparent;
    color: #424242;
    font-weight: 200;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
}

.btn-not-fill-custom.selected {
    background: var(--main);
    color: #fff;
}

.red-alert {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='22' ry='22' stroke='%23990000FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 22px;
}

.w-55 {
    width: 55px;
    position: relative;
    left: 11px;
}

.mt-3-2 {
    margin-top: 1.09rem;
}

.social-container-ul-g {
    margin: 0;
}

.social-container-ul-g li,
.social-container-ul-g li a {
    color: var(--main);
}

.social-container-ul-g li a:hover {
    color: var(--main-hover);
}

.site-name-custom {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #4D4D4D;
    font-family: numberFont;

}

.site-name-custom:hover {
    color: var(--main);
}

.back-light-g {
    border-radius: 12px;
    margin-bottom: 10px;
}

.back-light-g:nth-child(odd) {
    background: #FAFAFA;
}

.back-light-g:nth-child(even) {
    background: #F4F4F4;
}

.f-14-c7 {
    font-size: 16px;
    line-height: 25px;
    color: #252525;
    font-weight: 200;
}

.z-index-2-after.green-border:after {
    z-index: 2;
}

.back-light-g div .row div .green-border div div svg {
    z-index: 3 !important;
    position: relative;
}

.z-index-2-after.border-green-and-dashed.border-green-and-dashed-2 div {
    position: relative;
}


.back-light-g:nth-child(even) div .z-index-2-after.border-green-and-dashed.border-green-and-dashed-2 div:after {
    content: '';
    z-index: 3;
    background: url(./assets/svg/line-dash-red.svg) !important;
    width: 2px;
    height: 100%;
    display: block;
    position: absolute;
    right: 27px;
    top: 0;
}

.back-light-g:nth-child(even) div .row .col-12 .green-border.z-index-2-after:after {
    background: var(--red);
}

.back-light-g:nth-child(even) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .go-to-left,
.back-light-g:nth-child(even) div .row .col-12 .green-border.z-index-2-after .d-flex .go-to-left {
    color: var(--red);
}

.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .w-34 svg path[fill="#045E5E"],
.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .w-34 svg circle[fill="#045E5E"],
.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .w-55 svg path[fill="#045E5E"],
.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .w-55 svg circle[fill="#045E5E"],
.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .green-border.z-index-2-after .d-flex .w-34 svg path[fill="#045E5E"],
.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .green-border.z-index-2-after .d-flex .w-34 svg circle[fill="#045E5E"],
.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .green-border.z-index-2-after .d-flex .w-55 svg path[fill="#045E5E"],
.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .green-border.z-index-2-after .d-flex .w-55 svg circle[fill="#045E5E"] {
    fill: var(--red);
}

.back-light-g:nth-child(odd) div .z-index-2-after.border-green-and-dashed.border-green-and-dashed-2 div:after {
    content: '';
    z-index: 3;
    background: url(./assets/svg/line-dash-green.svg) !important;
    width: 2px;
    height: 100%;
    display: block;
    position: absolute;
    right: 27px;
    top: 0;
}

.back-light-g:last-child div .z-index-2-after.border-green-and-dashed.border-green-and-dashed-2 div:after {
    height: calc(100% + 6rem) !important;
}

.social-container-ul-g li a {
    color: #838383;
}

.back-light-g:nth-child(odd) div div div .green-border .d-sm-flex .header-title .social-container-ul-g li a:hover,
.back-light-g:nth-child(odd) div div div .green-border .d-flex .header-title .social-container-ul-g li a:hover {
    color: var(--main);
}

.back-light-g:nth-child(even) div div div .green-border .d-sm-flex .header-title .social-container-ul-g li a:hover,
.back-light-g:nth-child(even) div div div .green-border .d-flex .header-title .social-container-ul-g li a:hover {
    color: var(--red);
}

.back-light-g:nth-child(odd) div div div .green-border .d-sm-flex .header-title .social-container-ul-g li a:hover svg path[fill="#767676"],
.back-light-g:nth-child(odd) div div div .green-border .d-flex .header-title .social-container-ul-g li a:hover svg path[fill="#767676"] {
    fill: var(--main);
}

.back-light-g:nth-child(even) div div div .green-border .d-sm-flex .header-title .social-container-ul-g li a:hover svg path[fill="#767676"],
.back-light-g:nth-child(even) div div div .green-border .d-flex .header-title .social-container-ul-g li a:hover svg path[fill="#767676"] {
    fill: var(--red);
}

.back-red-imp {
    background: var(--red) !important;
    border-radius: 0;
}

.btn-red-color {
    color: var(--red) !important;
}

.btn-red-color:hover {
    background-color: var(--red-hover) !important;
    color: #fff !important;
}

.p-left {
    left: 5px;
    top: -5px;
}

.question-container-data {
    margin-right: 15px;
}

.filter-container > .botton-submit1.question-container-data {
    margin-top: 25px;
}

.w-100-200 .right-header a img.logo {
    padding-right: 10px;
}


.news-container-little .swiper {
    height: 440px;
}

.news-container-little .swiper .swiper-wrapper .swiper-slide img,
.news-container-little .swiper .swiper-wrapper .swiper-slide {
    width: 270px !important;
    height: 366px;
}

.user-slider-2-back-white .swiper .swiper-wrapper .swiper-slide a .header-user-image-container:after,
.user-slider-2-back-white .swiper .swiper-wrapper .swiper-slide .user-guide-2 .header-user-image-container:after {
    background: url(./assets/svg/guided-F.svg) no-repeat center;
}

.user-desc-container {
    width: 100%;
}

.user-slider-h-5 .swiper {
    height: 430px !important;
}

.user-slider-h-5 .swiper .swiper-wrapper .swiper-slide,
.user-slider-h-5 .swiper .swiper-wrapper .swiper-slide .user-guide .user-desc-container img,
.user-slider-h-5 .swiper .swiper-wrapper .swiper-slide .user-guide {
    width: 370px;
    right: 0;
}

.user-name-light {
    font-weight: 200;
    font-size: 15px;
    line-height: 16px;
    color: #4b4b4b;
}

.seminar-slider-500 .swiper {
    height: 500px;
}

.green-link {
    font-size: 14px;
    line-height: 25px;
    color: var(--main);
    font-family: numberFont;
}

.green-link:hover {
    color: var(--main-hover);
}

.seminar-slider-100 .swiper-button-prev,
.seminar-slider-100 .swiper-button-next {
    position: relative !important;
    margin-top: -7px;
}

.seminar-slider-100 .swiper-button-prev {
    top: 39px !important;
}

.seminar-slider-100 .swiper {
    height: 100%;
}

.f-main {
    font-family: mainFont;
}

.country-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #8B8B8B;
    font-family: mainFont;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;
}

.country-name img {
    margin-left: 7px;
}

.setting-container {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: fit-content;
    top: 5px;
}

.shia-c {
    font-size: 18px;
    color: #6D6D6D;
    font-weight: 200;
    font-family: mainFont;
    text-align: center;
    width: 108px;
    display: block;
}

.w-160 {
    width: 108px;
    height: 108px;
    display: block;
    background: #D9D9D9;
    border-radius: 100%;
    object-fit: cover;
}

.seminar-slider-w-160 .swiper .swiper-wrapper .swiper-slide {
    width: 108px;
}

.back-w .header-user-image-container:after {
    background: url(./assets/svg/guided-F.svg) no-repeat center;
}

.text-white-50 {
    color: #fff;
}

.text-white-60-2 {
    color: rgba(255, 255, 255, .75) !important;
}

.nav-left .swiper .swiper-button-prev {
    left: 50px;
    right: auto;
}

.nav-left .swiper .swiper-button-next {
    left: 0;
    right: auto;
}

.login-modal {
    position: fixed;
    display: block;
    width: 485px;
    height: 389px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    border-radius: 12px;
    margin-right: auto;
    margin-left: auto;
    z-index: 1001;
    background: #F7F7F7;
    transition: 1s;
}

.overlay-modal {
    position: fixed;
    background: #000000A5;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    opacity: 1;
}

.top-center svg {
    width: 150px;
}

.top-center {
    top: -150px;
    height: 150px;
    display: flex;
    align-items: center;
    transform: translateY(50%);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.mt-150 {
    display: block;
    margin-top: calc((150px / 2) + 15px);
    font-size: 18px;
    color: var(--main);
    font-weight: 400;
    text-align: center;
    padding-bottom: 5px;
}

.disc-14 {
    font-weight: 200;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #9E9E9E;
}

.dont-have-account {
    font-weight: 200;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: #B4B4B4;
    margin-top: 10px;

}

.dont-have-account-color-black {
    color: #8B8B8B;
    margin-right: 7px;
}

.width-height-150 {
    width: 150px !important;
    height: 150px !important;
    margin-bottom: 20px;
    background-color: #fff;
}

.send-code {
    font-size: 12px;
    line-height: 13px;
    color: #9C9C9C;
}

.send-code:hover span {
    color: var(--main);
}

.send-code:hover svg path[stroke="#B0B0B0"] {
    transition: 0.3s;
    stroke: var(--main);
}

.send-code svg {
    position: relative;
    top: -0.03rem;
}

.child-4 input {
    width: 30%;
}

.child-4 {
    direction: ltr;
}

.btn-close-modal {
    position: absolute;
    z-index: 1001;
    top: 6px;
}

.btn-close-modal:hover svg path {
    stroke: var(--red-hover);
}

.btn-close-modal svg {
    width: 25px !important;
}

.login-modal.close {
    top: -100%;
    transition: 1s;
}

.close.overlay-modal {
    visibility: hidden;
    opacity: 0;
    transition: 1s;
}

.more-btn.border-0 {
    border: 1px solid transparent !important;
}

.login-modal-h-big {
    height: 628px;
}

.login-modal-h-big-1 {
    height: 750px;
    overflow-y: auto;
}

.image-uploader {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
}

.image-uploader svg {
    width: 150px;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
}

.image-uploader .image-item {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}

.image-uploader .image-item__btn-wrapper {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    transform: translateY(-50%);
    right: -42px;
    margin: auto;
    display: flex;
    align-items: end;
}

.image-uploader .image-item img {
    width: 150px;
    height: 150px;
    background-color: #F7F7F7;
    border-radius: 50%;
    object-fit: scale-down;
    border: 4px solid var(--main);
}

.image-uploader .image-item .image-item__btn-wrapper div {
    bottom: -55px;
    right: -7px;
}

.image-uploader .image-item .image-item__btn-wrapper div:hover circle[fill="white"] {
    cursor: pointer;
    fill: var(--main);
}

.image-uploader .image-item .image-item__btn-wrapper div:hover path[stroke="#045E5E"] {
    stroke: #fff;
}

.login-modal-big {
    width: 976px;
    height: 481px;
    background: #F8F8F8 url(./assets/image/arm-middle.png) no-repeat right bottom;
    background-size: 400px;
}

.name-input-user {
    margin-top: 5.3rem !important;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: #939393;
    justify-content: center;
}

.name-input-user svg {
    margin-left: 7px;
}

.forms-module {
    position: fixed;
    z-index: 1001;
    background: url(./assets/image/arm-middle-1.png) no-repeat left top #F8F8F8;
    left: 200px;
    right: 200px;
    top: 140px;
    bottom: 140px;
    border-radius: 20px;
}

.header-tab ul {
    list-style: none;
    padding: 0;
    background-color: var(--main);
    width: 100%;
    margin-bottom: 0;
    border-radius: 15px;
}

.header-tab {
    width: -moz-fit-content;
    width: fit-content;
    display: inline-flex;
    height: 100%;
    padding: 48px;
    min-width: 408px !important;
}

.header-tab ul li {
    margin: 15px 0;
}

.header-tab ul li button {
    color: #FCFCFC;
    text-align: right;
    margin-left: 15px;
    margin-right: 15px;
    display: inline-block;
    width: -webkit-fill-available;
    border-radius: 10px;
}

.header-tab ul li button:hover {
    color: #FFFFFF;
    background: #FFFFFF66 !important;
}

.header-tab ul li button.selected {
    background: #FFFFFFF2 !important;
    color: var(--main-hover);
}

.form-body {
    padding: 48px 0 48px 48px;
    min-width: calc(100% - 408px);
}

.form-title {
    font-weight: 400;
    font-size: 18px;
    text-align: right;
    color: #045E5E;
    margin-bottom: 5px;
    margin-top: 10px;
}

.form-disc {
    font-weight: 200;
    font-size: 16px;
    line-height: 23px;
    text-align: right;
    color: #252525;
}

.f-12-g {
    font-family: numberFont;
    font-weight: 200;
    font-size: 13px;
    line-height: 16px;
    color: #313131;
}

.c-999 * {
    color: #252525 !important;
    font-size: 14px;
}

.left-0 {
    left: 0 !important;
}

.not-found-page-404 {
    background: url(./assets/image/arm-middle.png) bottom right no-repeat #EFEFEF;
    background-size: 600px;
    width: 100%;
    height: 100vh;
    border-top: 5px solid var(--main);
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-text-not-f-404 {
    font-size: 22px;
    color: var(--main);
}

.content-p-404 {
    text-align: center;
}

.text-404 {
    padding-left: 20px;
    position: relative;
    font-family: numberFont;
    color: #646464;
}

.content-p-404 span:not(.text-404) {
    line-height: 1;
    display: inline-block;
    position: relative;
    top: -2px;
    font-size: 18px;
    color: #646464;
    font-family: mainFont;
}

.text-404:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 2px;
    height: 20px;
    top: 7px;
    background-color: var(--red);
    left: 10px;
}

.link-to-home-page-f {
    background: var(--main) !important;
    color: #fff !important;
    font-size: 15px;
    padding: 8px 13px 7px 10px;
    border: unset;
    cursor: pointer;
    font-family: mainFont;
    font-weight: 200;
}

.link-to-home-page {
    border: 1px solid #737373;
    color: #737373;
    padding: 5px 8px 6px 10px;
    border-radius: 8px;
    font-size: 14px;
    display: inline-flex;
    align-items: end;
}

.link-to-home-page.link-to-home-page-f:hover {
    background-color: var(--main-hover) !important;
}

.link-to-home-page:not(.link-to-home-page-f):hover {
    background-color: var(--main) !important;
    color: #fff;
    border-color: var(--main);
}

.link-to-home-page:not(.link-to-home-page-f):hover svg path {
    stroke: #fff;
}

.section-footer-page-404 {
    position: absolute;
    bottom: 30px;
    right: 25px;
}

.social-media-container-footer-404 {
    text-align: left;
    font-size: 14px;
    margin-left: 13px;
}

.social-media-container-footer-404 span:last-child {
    color: #242424 !important;
}

.social-media-container-footer-404 span:first-child {
    color: #7c7c7c !important;
}

.social-media-container-footer-404 span {
    width: 106px;
    display: block;
    font-size: 12px;
    text-align: justify;
    color: #585858 !important;
    font-family: mainFont;
    padding: 0 8px;
    margin-left: 10px;
}

.social-media-container-footer-404:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 2px;
    height: 20px;
    top: 11px;
    background-color: var(--red);
    left: 0;
}

.social-list {
    list-style: none;
    display: flex;
    margin: 0;
    justify-content: end;
}

.social-list li {
    margin: 0 3px;
}

.social-list-w-21 svg {
    width: 25px;
}

.f-main-imp {
    font-family: mainFont !important;
}


.nav-right .swiper .swiper-button-next {
    right: 50px !important;
    left: auto !important;
}

.nav-right .swiper .swiper-button-prev {
    right: 0 !important;
    left: auto !important;
}

.h-300 {
    height: 305px;
}

.more-btn.color-white:hover {
    background: var(--main) !important;
    color: #fff !important;
    transition: 0.3s;
}

.close-input {
    display: none;
}

.w-sm-75 {
    width: 75%;
}

.books-module {
    padding-bottom: 0;
}

.icon-container-share {
    opacity: 1;
    visibility: visible;
    transition: 0.4s;
    position: absolute;
    list-style: none;
    display: flex;
    direction: ltr;
    left: 2px;
    top: -2px;
    background: #EEEEEE;
    gap: 8px;
    padding: 5px 11px;
    border-radius: 11px;
    transform: translateY(0);
}

.icon-container-share.close {
    opacity: 0 !important;
    visibility: hidden;
    transform: translateY(100%);
}

.icon-container-share li:hover svg path[fill="#AFAFAF"] {
    fill: var(--main)
}

.icon-container-share li:hover svg path[stroke="#959595"] {
    stroke: var(--main)
}

.icon-container-share li:hover svg path[stroke="#818181"] {
    stroke: var(--red);
}

.user-slider figure svg g path:hover {
    fill-opacity: 1 !important;
}

.pb-34 {
    padding-bottom: 3.4rem !important;
}

.floating-btn {
    bottom: 40px;
    z-index: 100;
    right: 40px;
    padding: 0;
    border-radius: 0 25px 25px 0;
}

.floating-btn span {
    position: absolute;
    right: -2px;
    z-index: 1;
    background: #EEEEEE;
    padding: 13px 30px 13px 13px;
    border-radius: 24px 0 0 24px;
    font-size: 14px;
    font-weight: 200;
    color: #535353;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.5);
    transition: 0.4s;
}

.floating-btn svg {
    z-index: 2;
    position: relative;
    box-shadow: 0 2px 3px #0000004f;
    border-radius: 50%;
}

.floating-btn:hover span {
    right: 28px;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition: 0.4s;
}

.floating-btn svg circle[fill="#045E5E"] {
    transition: 0.3s;
}

.floating-btn:hover svg circle[fill="#045E5E"] {
    fill: var(--red);
}

.like {
    cursor: pointer;
}

.error-message {
    font-size: 12px;
    font-weight: 200;
    text-align: right;
    color: #DF4343;
    white-space: nowrap;
}

.b--4 {
    bottom: -4px;
}

.error-message svg {
    margin: 0 1px 0 4px;
}

.form-container-c-black div input.error {
    border-color: #DF4343 !important;
}

.success-message {
    z-index: 100000000000;
    top: 20px;
    right: 50%;
    transform: translateX(50%);
    padding: 10px 20px;
    background: #24A148;
    width: fit-content;
    border-radius: 10px;
    min-width: 250px;
    text-align: right;
    transition: 0.7s;
}

.success-message.close {
    top: -50px;
}

.success-message span {
    font-size: 14px;
}

.w-23 {
    width: 23px;
    height: 22px;
}

.bg-color-red {
    background-color: #DF4343;
}

div button.focus-border-0:focus {
    border: 0 solid transparent !important;
}

.text-gray {
    font-size: 12px;
    line-height: 13px;
    color: #9C9C9C;
    text-align: start;
    font-family: numberFont;
    margin-bottom: 9px;
    margin-top: 15px;
}

.list-country {
    font-size: 13px;
    font-weight: 100;
}

.list-country-ul {
    max-height: 100px;
    overflow-y: scroll;
    position: absolute;
    z-index: 2;
    background-color: rgb(247 247 247);
    left: 0;
    right: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}

.list-country-ul::-webkit-scrollbar {
    width: 0;
}

.list-country-ul {
    scrollbar-width: none;
}

.list-country:hover {
    color: var(--main) !important;
}

.filter-b-and-t {
    height: 100px;
    left: 0;
    right: 0;
    opacity: 1;
    transition: 0.3s;
}

.filter-b-and-t:after {
    content: '';
    width: 100%;
    height: 15px;
    background: rgb(247 247 247);
    background: linear-gradient(0deg, rgb(247 247 247) 40%, rgba(0, 212, 255, 0) 100%);
    position: absolute;
    bottom: 0;
    z-index: 3;
}

.filter-b-and-t:before {
    content: '';
    width: 100%;
    height: 15px;
    position: absolute;
    background: rgb(247 247 247);
    background: linear-gradient(180deg, rgb(247 247 247) 40%, rgba(0, 212, 255, 0) 100%);
    top: 0;
    z-index: 3;
}

.fade-this {
    visibility: hidden;
    opacity: 0;
}

.color-main-4 {
    color: var(--main);
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: 0;
}

.ant-select {
    width: 100% !important;
    height: fit-content !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: transparent !important;
    background: transparent !important;
    width: 100% !important;
    text-align: right;
    padding-right: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ant-select * {
    font-family: mainFont !important;
}


.ant-select-selector {
    box-shadow: unset !important;
}

.ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: 14px;
    font-weight: 200 !important;
    color: #252525 !important;
}

.ant-select .ant-select-arrow .anticon > svg {
    width: 14px;
    height: 12px;
}

.show-icon-pass-right span {
    position: absolute;
    left: -18px;
}

.ant-select-selector {
    padding: 0 !important;
}

.ant-select-arrow {
    left: 0;
}

.child-m-0 p {
    margin: 0 !important;
}

.overlay-play-2 {
    transform: translate(50%, -40px);
    top: 50%;
    background-size: 70px;
    width: 70px;
    height: 70px;
}

.hidden-desktop-flex {
    display: none !important;
}

.select__indicator {
    cursor: pointer;
}

.select__clear-indicator {
    padding: 0 !important;
}

.input-group.search-input-group.search-input-group-2.position-relative.w-input.focus-hover .input-group-btn-22,
.input-group.search-input-group.search-input-group-2.position-relative.w-input.focus-hover input,
.input-group.search-input-group.search-input-group-2.position-relative.w-input:hover .input-group-btn-22,
.input-group.search-input-group.search-input-group-2.position-relative.w-input:hover input {
    border-color: #fff !important;
}

.not-funded-filter {
    padding: 10px 15px;
    background: var(--main);
    border-radius: 10px;
    color: #fff;
    font-weight: 200;

}

.child-m-s-0 div {
    margin: 0 !important;
}

.border-0-im:focus,
.border-0-im {
    border: 0 !important;
}

.phone-modal-container {
    display: none;
}

.select__menu-list {
    padding-right: 4px !important;
    padding-left: 4px !important;
}

@media (min-width: 1000px) and (max-width: 1300px) {
    .basic-single {
        width: 86px !important;
    }

    .select__value-container {
        padding-left: 0 !important;
    }
}


@media (min-width: 1000px) and (max-width: 1370px) {
    .aq-menu-head.filter-container-mt.back-main-2 .aq-container .row .w-200.width-0-phone {
        width: 100px;
    }
}


@media (min-width: 1000px) and (max-width: 1100px) {
    .w-input {
        width: 227px !important;
    }

    .w-200 {
        width: 198px;
    }

}

.top-3 {
    top: 3px;
}

.last-child-no-border > div:nth-last-child(2) hr {
    display: none !important;
}

.select__value-container {
    cursor: pointer !important;
}

.width-14-px {
    width: 152px !important;
}

.fslightbox-slide-number-container {
    margin-left: 0;
    margin-right: auto;
}

.txt-red-color {
    color: var(--red) !important;
}

.date-container-news svg path[stroke="#fff"] {
    stroke: rgba(255, 255, 255, 0.9) !important;
}

.date-container-news .date.text-white span,
.date-container-news .date.text-white {
    color: rgb(255 255 255 / 80%) !important;
}

.alphabet-subjects-container {
    width: calc(100% + 100px);
    background: #fff;
    z-index: 100;
    border-radius: 10px;
    padding: 15px;
    top: 0;
}

.alphabet-subjects-container div ul li {
    text-align: center;
    padding: 3px 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: var(--main);
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
}

.alphabet-subjects-container div ul li:hover {
    background-color: #045E5E26;
}

.alphabet-subjects-container div ul li.select {
    background-color: var(--main);
    color: #fff !important;
}

.alphabet-subjects-container .d-flex ul {
    background-color: #ECECEC;
    padding: 5px 7px !important;
}

.alphabet-subjects-container .d-flex ul.first-c {
    border-radius: 0 10px 10px 0;
    padding-left: 0 !important;
}

.alphabet-subjects-container .d-flex ul.second-c {
    border-radius: 10px 0 0 10px;
}

.subject-container-div {
    max-height: 463px;
    width: 100%;
    list-style: none;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0;
    padding-top: 3px;
    padding-left: 0 !important;
}

.subject-container-div > li {
    font-size: 14px !important;
    font-weight: 200 !important;
    color: #7C7C7C !important;
    color: #7C7C7C !important;
    margin-bottom: 5px !important;
    padding: 5px 10px !important;
    border-radius: 8px !important;
    width: 100% !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    text-align: right !important;
}

.subject-container-div > li:hover {
    background-color: var(--main) !important;
    color: #fff !important;
    cursor: pointer;
}

.f-15-w {
    font-size: 15px;
    color: var(--main);
    font-weight: bold;
}

.alphabet-subjects-container div ul li.disable {
    color: #045E5E66;
    background-color: transparent !important;
    cursor: default;
}

.select-by-filter p {
    font-weight: 200;
}

.not-funded-filter-1 {
    font-size: 12px;
    padding-top: 22px;
    display: block;
    color: var(--red);
}

.height-100-sc {
    height: 512px;
}

.height-100-sc div div::-webkit-scrollbar {
    width: 0;
}

.height-100-sc div div {
    scrollbar-width: none;
}

.height-100-sc > div {
    overflow: unset !important;
}

.height-100-sc div div .w-100.mt-3 {
    padding-right: 20px;
}

.download-btn-g {
    padding-left: 15px;
    padding-right: 15px;
}

.download-btn-g:hover {
    background-color: var(--red);
    color: #fff !important;
}

.download-btn-g:hover svg path {
    fill: #fff;
}

.download-btn-g:hover svg path[stroke="white"] {
    stroke: #fff;
    fill: transparent;
}

.download-btn-g svg {
    position: relative;
    top: -1px;
}

.statistics span {
    display: block;
    color: #fff;
}

.subject-container-div li {
    display: flex;
    width: fit-content !important;
}

.position-relative.select div.select-by-filter {
    background-color: rgb(231 235 235) !important;
}

.position-relative.select div p {
    color: var(--main) !important;
}

.position-relative.select div svg path {
    stroke: var(--main);
}

.border-red-and-dashed-4 div:after {
    background: url(./assets/svg/line-dash-red-4.svg) no-repeat !important;
    z-index: 1 !important;
}

.btn-show-answer:hover {
    color: #fff;
    background-color: var(--red);
}

.p-15px {
    padding: 0 15px;
}

.form-container--3 div textarea {
    border: none !important;
    background: transparent;
    border-bottom: 1px solid #FFFFFF33 !important;
    font-size: 14px;
    font-weight: 200;
    padding-bottom: 5px;
}

.personal-information li:nth-child(2) {
    width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.personal-information li:first-child {
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.personal-information li:nth-child(3) {
    width: 232px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.personal-information-2 li:first-child:first-child {
    width: 110px !important;
}


.personal-information-2 li:nth-child(3) {
    width: 115px !important;
}

.w-70 {
    width: 70px !important;
}

.w-66-h {
    width: 60px !important;
}

.parallax-data.question-container svg {
    width: 55px;
    position: relative;
    top: -4px;
}

.parallax-data svg {
    position: relative;
    top: -5px !important;
}

.parallax-data.submitted-answer-container svg {
    top: -4px !important;
}

.fs-12 {
    font-size: 14px;
}

.social-container-ul-filter li button.selected div svg path[stroke="#868686"] {
    stroke: #fff
}

.bookmark-this {
    cursor: pointer;
}

.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .bookmark-this svg path[stroke="#045E5E"] {
    stroke: var(--red);
}

.top-28 {
    top: -28px
}

.user-option {
    padding: 60px 8px 3px;
    list-style: none;
    position: absolute;
    z-index: 100;
    left: -7px;
    top: -9px;
    background: #E4E4E4;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
}

.user-option.open {
    opacity: 1;
    visibility: visible;
}

.user-option li {
    padding: 7px;
    margin-bottom: 10px;
    border-radius: 100%;
}

.login-container.position-relative img {
    object-fit: cover;
    z-index: 101;
    position: relative;
    cursor: pointer;
    border-radius: 100%;
}

.user-option li:hover {
    background-color: var(--main);
}

.user-option li:hover svg path[fill="#9D9D9D"] {
    fill: #fff;
}

.user-option li:hover svg circle[stroke="#9D9D9D"],
.user-option li:hover svg path[stroke="#9D9D9D"] {
    stroke: #fff;
}

.fs-13 {
    font-size: 13px !important;
}

.all-child-text-w * {
    color: #fffc !important;
}

.all-child-text-w div div {
    margin: 7px 0 0 0 !important;
}

form > div.col-4.all-child-text-w.d-flex.mx-auto.my-2 > div > div > span.ant-select-selection-placeholder,
.all-child-text-w div div span input {
    color: #fffc !important;
}

.form-container--3 {
    background: #990000;
}

.input-border-bottom.w-100 {
    height: 29px;
}

.child-3-m-0 .ant-select .ant-select-selector {
    margin: 0 !important;
}

.bookmark {
    cursor: pointer;
}

.bookmark.disabled,
.bookmark.disabled span {
    cursor: not-allowed !important;
}

.bookmark.disabled svg path[stroke="#909090"] {
    stroke: #b2b2b2;
}

.z-in-5 {
    z-index: 5;
    position: relative;
}

.c-list-container-2 {
    position: absolute;
    top: 35px;
    width: 290px;
    background: #F8F8F8;
    border-radius: 10px;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
    padding-bottom: 17px;
    box-shadow: 0 0 7px 0px #0000002e;
}

.c-list-container-2.open {
    visibility: visible;
    opacity: 1;
}

.c-list-container-2 ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    height: 181px;
    overflow-y: auto;
}

.c-list-container-2 ul li {
    padding: 2px 20px;
    margin: 2px;
    border-radius: 7px;
    font-size: 14px;
}

.c-list-container-2 ul li img {
    border-radius: 4px;
    margin-left: 5px;
}

.c-list-container-2 ul li.selected,
.c-list-container-2 ul li:not(.no-hover-imp):hover {
    background: var(--main);
    color: #fff;
}

.search-input-group-3 {
    padding: 0 20px !important;
}

.navi33 a {
    color: #252525;
    padding: 5px 15px;
}

.navi33 a.selected {
    background: var(--red);
    color: #fff;
    border-radius: 10px;
}

.navi33 {
    background: #EFEFEF;
    padding: 7px;
    position: relative;
    top: -27px;
}

.force-first-g:first-child {
    background: var(--main);
    border-radius: 0 !important;
}

.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after:after {
    background: #fff !important;
}

.force-first-g:first-child div .z-index-2-after.border-green-and-dashed.border-green-and-dashed-2 div:after {
    background: url(./assets/svg/line-dash-white.svg) !important;
}

.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-sm-flex .header-title h2,
.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-flex .header-title h2 {
    color: #fff;
    font-size: 1.07rem;
}

.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-sm-flex .header-title p,
.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-flex .header-title p {
    color: #fff;
    font-family: numberFont;
    font-weight: 100;
}

.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-sm-flex .header-title ul li .key-w,
.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-flex .header-title ul li .key-w {
    color: rgb(255 255 255 / 70%);
    font-size: 16px;
    padding-left: 5px;
}


.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .header-title ul li .key-w,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .header-title ul li .key-w {
    color: #717171;
    font-size: 16px;
    padding-left: 5px;
}

.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .header-title ul li .value-w,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .header-title ul li .value-w {
    font-size: 16px;
    color: #4f4f4f;
}

.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-sm-flex .header-title ul li .value-w,
.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-flex .header-title ul li .value-w {
    color: rgba(255, 255, 255, 0.98);
    font-weight: 100;
    font-size: 16px;
}

.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after:after {
    background: var(--main);
}

.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .w-34 svg circle,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .w-55 svg circle {
    fill: var(--main);
}

.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .w-34 svg path[stroke="#045E5E"],
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .w-55 svg path[stroke="#045E5E"],
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .w-34 svg path[stroke="#045E5E"],
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .w-55 svg path[stroke="#045E5E"] {
    stroke: #fff
}

.force-first-g:not(:first-child) div .z-index-2-after.border-green-and-dashed.border-green-and-dashed-2 div:after {
    background: url(./assets/svg/line-dash-green.svg) !important;
}

.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-sm-flex .go-to-left,
.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-flex .go-to-left {
    color: #fff;
}

.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .go-to-left,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .go-to-left {
    color: var(--main);
}

.color-2 {
    color: #FFFFFFE5;
}

.number-font {
    font-family: numberFont !important;
    font-size: 12px;
}


.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-sm-flex .header-title.mt-1.me-2,
.force-first-g:first-child div .row .col-12 .green-border.z-index-2-after .d-flex .header-title.mt-1.me-2 {
    color: rgba(255, 255, 255, 0.98);
}

.force-first-g div .row .col-12 .green-border.z-index-2-after .d-sm-flex .header-title.mt-1.me-2,
.force-first-g div .row .col-12 .green-border.z-index-2-after .d-flex .header-title.mt-1.me-2 {
    font-size: 16px;
}

.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .w-55 svg circle,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .w-55 svg rect,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .w-55 svg rect {
    fill: var(--main);
}

.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .w-55 svg circle[stroke="#045E5E"],
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .w-55 svg circle[stroke="#045E5E"] {
    stroke: #fff
}

.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .d-flex .ms-2 path[stroke="white"],
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .d-flex .ms-2 path[stroke="white"] {
    stroke: #808080 !important;
}

.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .d-flex,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .d-flex .text-white-80,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-sm-flex .text-white-90,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .d-flex,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .d-flex .text-white-80,
.force-first-g:not(:first-child) div .row .col-12 .green-border.z-index-2-after .d-flex .text-white-90 {
    color: #808080 !important;
}

.user-image-change {
    background: #fff;
    border-radius: 100%;
    object-fit: cover;
    border: 1px solid;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
}

.number-search {
    color: #fff;
    font-family: numberFont;
}

.color-w-70 {
    color: #FFFFFFB2;
}

.font-child-main * {
    font-family: mainFont !important;
}


.position-relative-top-0 {
    position: relative;
    top: -51px;
    width: fit-content;
}

.news-container-little-big .swiper {
    height: 460px;
}

.news-container-little-big .swiper .swiper-wrapper .swiper-slide img,
.news-container-little-big .swiper .swiper-wrapper .swiper-slide {
    width: 261px !important;
    height: 367px !important;
}

.user-slider-5.swiper .swiper-wrapper .swiper-slide {
    width: 300px !important;
}

.seminar-slider .swiper .more-btn-container.d-flex.justify-content-end.mt--52 {
    margin-top: -2.5rem;
}

.col-12.seminar-slider.seminar-slider-100.seminar-slider-102.seminar-slider-33.seminar-slider-w-160.pe-3 .swiper .swiper-wrapper {
    height: 220px;
}

.container-c-pointer span:not(.main-search) {
    cursor: pointer;
}

.f-16-imp {
    font-size: 16px;
}

.swiper-wrapper:after {
    content: '';
    flex-shrink: 0;
    width: 2000px;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
}

.seminar-slider-44 {
    height: 270px !important;
}

.selected.bookmark svg path {
    stroke: var(--main);
    fill: var(--main);

}

.bookmark ~ div {
    cursor: pointer;
}

.z-index-10 {
    z-index: 100;
}

.icon-container-share-4 {
    transform: translateY(-7%) !important;
    left: 0;
}

.left-15 {
    left: 15px;
    top: 33px;
    z-index: 100;
}

.media-changer.selected svg path[stroke="#A6A6A6"],
.media-changer:hover svg path[stroke="#A6A6A6"] {
    stroke: #fff;
}

.media-changer.selected svg path[stroke="#a59e9e"],
.media-changer:hover svg path[stroke="#a59e9e"] {
    stroke: var(--main);
}

.media-changer.selected svg path[fill="#D9D9D90"],
.media-changer:hover svg path[fill="#D9D9D90"] {
    fill: var(--main);
}

a.speaker-name-title:hover {
    color: var(--main);
}

#myAudio {
    background-color: #f1f3f4;
    border-radius: 0 0 17px 17px;
    position: absolute;
    left: 12px;
    bottom: 0px;
    right: 12px;
    width: -webkit-fill-available;
}

.mb-2-custom {
    margin-bottom: 7px;
}

.bookmark-this:not(.disabled):hover svg path[fill="#E6E6E6"],
.bookmark-this:not(.disabled).select svg path[fill="#E6E6E6"] {
    fill: var(--main);
}

.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .bookmark-this:not(.disabled).select svg path[stroke="#045E5E"],
.back-light-g:not(.back-red-imp):nth-child(even) div .row .col-12 .bookmark-this:not(.disabled):hover svg path[stroke="#045E5E"] {
    fill: var(--red);
}

.bookmark-this.disabled:hover {
    cursor: not-allowed;
}

.child-m-0-in div div,
.child-m-0-in div {
    margin: 0 !important;
}

.email-input {
    direction: ltr;
    text-align: left;
}

.email-input::placeholder {
    direction: rtl;
    text-align: right;
}

.z-index-2-after.light-green.green-border:after {
    background: #FFFFFF7F !important;
}

.back-light-g:nth-child(odd) div .z-index-2-after.light-green.border-green-and-dashed.border-green-and-dashed-2 div:after {
    background: url(./assets/svg/line-dash-green-light.svg) !important;
}

.soc-input {
    text-align: left;
    direction: ltr;
}

.more-btn.hover-red:hover {
    background: var(--red-hover) !important;
}

button.hover-svg-f:hover svg circle,
button.hover-svg-f svg path {
    transition: 0.3s;
}

button.hover-svg-f:hover svg circle,
button.hover-svg-f:hover svg path {
    stroke: #fff !important;
}

.hover-svg-f {
    padding-top: 6px;
}

.select-pos {
    font-size: 16px;
    position: relative;
    top: 2px;
    padding-right: 7px;
}

.height-160 {
    height: 160px !important;
    overflow: hidden !important;
}

.this-is-light-red-imp.border-green-and-dashed div:after {
    display: none !important;
}


.this-is-light-red-imp.border-green-and-dashed div::before {
    content: '';
    z-index: 3;
    background: url(./assets/svg/line-dash-red-4.svg) !important;
    width: 2px;
    height: 100%;
    display: block;
    position: absolute;
    right: 27px;
    top: 0;
}

.color-red-se .ant-select .ant-select-arrow span,
.color-red-se .ant-select .ant-select-selector .ant-select-selection-placeholder,
.color-red-se .ant-select .ant-select-selector .ant-select-selection-item {
    color: #FFFFFF99 !important;
    font-weight: lighter;
}

.line-2-g-t-l {
    text-align: left;
    top: -3px;
}

input[type="date"]:not(.icon-black)::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.more-btn.h-green-hover:hover {
    background: var(--main-hover) !important;
}

.forms-module {
    transition: 0.7s;
    opacity: 1;
    visibility: visible;
}

.forms-module.close {
    opacity: 0;
    visibility: hidden;
}

@media (min-width: 900px) and (max-width: 1600px) {
    .form-body {
        padding: 34px 0 34px 34px;
        min-width: calc(100% - 408px);
    }

    .forms-module {
        left: 63px;
        right: 63px;
        top: 78px;
        bottom: 78px;
    }

    .header-tab {
        padding: 34px;
        min-width: 296px !important;
    }

    .social-container-filter svg {
        width: 95px;
    }
}


@media (min-width: 900px) and (max-width: 160000px) {
    .push-bottom-in-form {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        width: calc(100% - 45px);
        padding: 0;
    }

    .w-380 {
        width: 440px !important;
    }

    .gh-padding {
        padding-right: 6rem !important;
        padding-left: 6rem !important;
    }
}

.col-black.color-label-f2 {
    color: #212529 !important;
}

.checkbox-pop-2 + label span:before,
.checkbox-pop-2 + label span:after {
    border: 1px solid rgb(95 98 101);
}

.col-md-4 .ant-select .ant-select-arrow {
    left: 0 !important;
}

.child-m-2 li {
    margin-bottom: 5px;
}

.search-main .aq-container .row.border-green-and-dashed.border-green-and-dashed-2 {
    height: 100px !important;
}

.last-Religion svg {
    margin-left: 5px;
}

.color-8080 {
    color: #444444 !important;
}

.news-date {
    font-size: 11px !important;
}

.select__clear-indicator ~ span ~ .select__dropdown-indicator {
    display: none;
}

.select__indicators .select__clear-indicator {
    padding-left: 6px !important;
}

.select__value-container {
    padding-left: 0 !important;
}

.fw-child-100 button {
    font-weight: 100 !important;
}

.form-control-45::placeholder,
.form-control-45 {
    color: #fff !important;
}

img.image-loading {
    width: 16px;
}

.like-and-bookmark div:last-child:hover svg path[stroke="#909090"] {
    stroke: var(--main);
}

.bookmark:hover svg path[stroke="#909090"] {
    stroke: var(--main);
}

.select-by-filter.d-flex.align-items-center.c-pointer.h-100 {
    width: 300px;
}

.select-by-filter.d-flex.align-items-center.c-pointer.h-100 p {
    width: 100%;
}

.alphabet-subjects-container .d-sm-flex .subject-container-div li {
    width: calc(100% - 15px) !important;
    overflow: hidden;
}

.alphabet-subjects-container .d-sm-flex .subject-container-div li span {
    width: 100%;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

.fill-08 {
    width: 40px;
}

.fill-08 path {
    fill: #fff;
    fill-opacity: 0.8;
}

.fill-08 path:hover {
    fill-opacity: 1;
}

.card-question .d-flex .left.hidden-phone .date {
    font-size: 13px;
    color: #313131;
}

.seminar-contianer.seminar-container-w .px-4 .speech-name.mt-5.f-main.text-center {
    min-height: 61px;
}

.seminar-slider-w-160 .swiper .swiper-wrapper .swiper-slide a {
    text-align: center;
}

.social-container-ul.text-start.mb-3.social-container-ul-g li a:hover svg path[fill="#767676"] {
    fill: var(--main);
}

.filter-container .botton-submit1.question-container-data .search-sub-menu .input-group .search-text-box-1.form-control::placeholder,
.filter-container .botton-submit1.question-container-data .search-sub-menu .input-group .search-text-box-1.form-control {
    color: #FFFFFFCC !important;
}

.seminar-slider-3 .swiper {
    height: 490px;
    position: relative;
}

.cursor-wait * *,
.cursor-wait *,
.cursor-wait {
    cursor: wait !important;
}

.flex-col-d {
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.back-g-imp {
    background-color: var(--main);
    padding-left: 0 !important;
}

.hover-back-g:hover {
    background-color: var(--main-hover);
}

.hover-back-g svg path {
    transition: 0.3s;
}

.hover-back-g:hover svg path {
    stroke: #fff;
}

.btn-bottom-shia {
    position: absolute;
    left: 30px;
    bottom: 30px;
}

.after-dnone:after {
    display: none;
}

.max-width-30 {
    max-width: 309px !important;
    overflow: hidden;
    white-space: nowrap;
}

.max-width-30 svg {
    width: 33px !important;
}

.w-100-30 {
    max-width: calc(100% - 50px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.change-close-space {
    left: 26px !important;
    top: 25px !important;
}

.radio-label > input[type="radio"] + .back-fff-t::before {
    background: transparent !important;
    border-color: var(--main-hover) !important;
}

.radio-label > input[type="radio"]:checked + .back-fff-t::before {
    box-shadow: inset 0 0 0 4px var(--main-hover);
}

.like-and-dislike.selected svg path {
    fill: var(--main);
}

.ant-select-arrow .anticon.anticon-down svg {
    display: none;
}

.form-container div input:focus,
.form-container div input:hover {
    color: #fff !important;
    border-color: #fffffff7 !important;
}

.form-container.form-container-c-black div textarea:focus,
.form-container.form-container-c-black div textarea:hover,
.form-container.form-container-c-black div input:focus,
.form-container.form-container-c-black div input:hover {
    color: var(--main) !important;
    border-color: var(--main) !important;
}

.select__value-container.select__value-container--has-value {
    background: rgb(231 235 235);
    color: var(--main);
    border-radius: 0 8px 8px 0;
    border: 1px solid rgb(231 235 235);
}

.select__value-container.select__value-container--has-value ~ .select__indicators {
    background: rgb(231 235 235);
    border-radius: 8px 0 0 8px;
}

.select__value-container.select__value-container--has-value div {
    color: var(--main) !important;
    font-weight: bold;
}

.select__value-container.select__value-container--has-value ~ .select__indicators .select__indicator svg path {
    fill: var(--main-hover) !important;
}

.item-selected {
    background: rgb(231 235 235);
    border-radius: 8px;
}

.item-selected p {
    color: var(--main) !important;
    font-weight: bold;
}

.item-selected .select-by-filter.h-100 svg path {
    fill: var(--main-hover) !important;
}

.f-16-seo {
    font-size: 16px;
}

.top-32 {
    bottom: 104px;
}

.modal-faq-cat {
    position: fixed;
    top: 70px;
    bottom: 70px;
    left: 70px;
    right: 70px;
    z-index: 10000;
    border-radius: 30px;
    overflow: hidden;
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
}

.modal-faq-cat.modal-close {
    opacity: 0;
    visibility: hidden;
}

.modal-faq-cat .header-green {
    padding: 10px 20px;
}

.modal-faq-cat .header-green {
    padding: 21px 38px;
    background: var(--main);
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

.all-subjects-faq-list a {
    display: inline-block;
}

.all-subjects-faq-list {
    background-color: #fff;
    padding: 17px 28px;
    overflow: auto;
    height: calc(100vh - 208px);
    text-align: center;
}

.all-subjects-faq-list > div > div {
    margin: 0 !important;
    scrollbar-width: none;
    text-align: center;
}

.all-subjects-faq-list > div > div::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.all-subjects-faq-list > div {
    overflow: visible !important;
}

.all-subjects-faq-list > div > div > div:last-child {
    right: -8px;
}

.big-form .search-sub-menu {
    width: 300px;
}

.not-fund-center > div > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.floating-btn-2:hover svg circle[fill="#cddfdf"] {
    fill-opacity: 1;
    fill: var(--main);
}

.floating-btn-2:hover svg rect[stroke="#045E5E"],
.floating-btn-2:hover svg path[stroke="#045E5E"] {
    stroke: #fff;
}

.g-b-se.selected {
    background-color: var(--main);
    color: #fff;
    border-color: var(--main);
}

.z-index-10 {
    z-index: 10;
}

.green-border-32:after {
    right: -13px;
}

.footer-option-btn a svg,
.footer-option-btn button svg {
    scale: 1.2;
}

/*.footer-option-btn button.scale-down svg {*/
/*    scale: 1.1;*/
/*}*/

.min-h-42 {
    min-height: 42px !important;
}

.left-tab li.active svg circle:not(.active-white) {
    fill: transparent !important;
}

.left-tab li:not(:nth-child(3)).active svg circle.active-white,
.left-tab li:not(:nth-child(3)).active svg path {
    stroke: #fff;
}

.left-tab li:not(.active) svg *[fill="#045E5E"] {
    fill: #EDEDED !important;
}

.left-tab li:nth-child(3):not(.active) svg path[fill="#EEEEEE"] {
    fill: #EEEEEE !important;
}

.left-tab li:nth-child(3):not(.active) svg path[fill="#045E5E"] {
    fill: #045E5E !important;
}

.left-tab li:not(.active) svg circle.active-white {
    stroke: #045E5E !important;
}

.left-tab li:not(:nth-child(3),.active) svg path[fill="white"] {
    fill: #045E5E !important;
}

.left-tab li:not(:nth-child(3),.active) svg path[stroke="white"] {
    stroke: #045E5E !important;
    fill: transparent;
}

.left-tab li:nth-child(3):not(.active) svg path[stroke="white"] {
    stroke: #045E5E !important;
    fill: transparent;
}

.left-tab li:nth-child(4) > svg > path[stroke="#fff"] {
    stroke: #045E5E !important;
}

.left-tab li.active:nth-child(4) > svg > path[stroke="#fff"] {
    stroke: #fff !important;
}

.left-tab li:not(:nth-child(3)).active svg path[fill="white"] {
    fill: #fff !important;
    stroke: transparent !important;
}

.mr-80 {
    margin-left: 80px !important;
}

.width-min-h {
    min-height: auto !important;
}

.link-to-home-page.link-to-home-page-f {
    border-color: var(--main) !important;
}

.c-pointer.media-changer svg {
    height: 30px !important;
}

.ant-select:not(.ant-select-open) .ant-select-arrow {
    background: url(./assets/svg/chevron-down.svg) no-repeat center !important;
    background-size: 16px !important;
    display: block;
    width: 20px;
    height: 20px;
}

.ant-select .ant-select-arrow {
    left: 0;
}

.col-sm-7.col-12.position-relative .green-border .d-flex.mt-4 .header-title * {
    font-family: mainFont !important;
}

.col-sm-7.col-12.position-relative .green-border .d-flex.mt-4 .header-title img {
    max-width: 100%;
}

.colse-l-t {
    position: absolute;
    left: 10px;
    top: 50px;
    cursor: pointer;
}

.flex-2-fix {
    position: fixed;
    z-index: 1000000000000000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.transform-0-imp {
    transform: translateY(0) !important;
}

.back-light-g.radius-0 {
    border-radius: 0;
}

.grecaptcha-badge {
    visibility: hidden;
}

.dir-ltr {
    direction: ltr;
    display: inline-block;
}

.max-w-50 {
    max-width: 50%;
}

.child-ff span {
    color: #fff !important;
}

.sticky-fix {
    top: 0 !important;
    left: 0;
    right: 0;
    z-index: 1000;
    position: fixed;
    box-shadow: 0 1px 20px 0 #000000cf;
}

#sticky-fix-changer {
    transition: 0.3s;
    width: 100%;
    margin: 0;
    top: -59px;
}

.min-h-55 {
    height: 90px;
    display: flex;
    align-items: end;
}

.sticky-fix .aq-container .row .w-200 {
    width: 0;
}

.sticky-fix .aq-container .row .w-100-200 {
    width: 100%;
    padding-right: 0;
    justify-content: center !important;
}

.more-btn-not-fill:focus,
.more-btn-not-fill:not(:disabled),
.more-btn-not-fill:hover,
.more-btn-not-fill {
    border: 1px solid var(--main) !important;
    background: transparent;
    color: #5B5B5B !important;
    border-radius: 10px;
    padding: 7px 28px;
    cursor: default !important;
}

.min-h-container {
    min-height: 24px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.child-over-hidden > div > div div div .w-100.mt--2-5 {
    overflow: hidden;
}

.mt--2-7 {
    margin-top: -2.7rem;
}

.mt--2-7 .col-10 .green-border div .w-100 .more-btn {
    z-index: 10;
}

.sticky-logo {
    width: 40px;
}

.sticky-logo-container {
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: 0.3s;
}

.sticky-fix .aq-container .row .w-100-200 .navbar .collapse .navbar-nav .sticky-logo-container {
    opacity: 1;
    visibility: visible;
    display: block;
}

.btn-not-fill.without-hover:not(.selected):hover,
.without-hover:hover {
    background: transparent !important;
    border: 1px solid #CFCFCF !important;
}

.text-green-main {
    color: var(--main);
    font-weight: 800;
    line-height: 27px;
    font-size: 17px;
}

.max-w-58 {
    max-width: 58%;
}

.book-value-container {
    margin-top: 10px;
}

.new-change-font-size {
    font-size: 18px;
    padding-right: 4px;
    padding-left: 4px;
}

.top--6 {
    position: relative;
    top: -10px;
}

.top--6 .go-to-left {
    right: -12px;
}

.green-subject {
    color: var(--main);
    font-size: 12px;
    font-family: 'numberFont';
    font-weight: 400;
}

.alphabet-subjects-container .d-sm-flex .subject-container-div li:hover span .green-subject,
.alphabet-subjects-container .d-sm-flex .subject-container-div li.select span .green-subject {
    color: #fff;
}

.btn-not-fill.select:not(:hover) .green-subject {
    color: #fff;
}

.subject-container-scroll .content ul li.select .green-subject,
.subject-container-scroll .content ul li:hover .green-subject {
    color: #fff;
}

.new-book-image {
    object-fit: cover;
    height: 240px;
}


.books-module-2.nav-green .aq-container .swiper .swiper-button-next:after {
    background: url(./assets/svg/owl-left-active.svg) no-repeat center !important;
    background-size: 40px !important;
}

.books-module-2.nav-green .aq-container .swiper .swiper-button-next:hover:after {
    background: url(./assets/svg/owl-left-active-2.svg) no-repeat center !important;
    background-size: 40px !important;
}

.books-module-2.nav-green .aq-container .swiper .swiper-button-prev:after {
    background: url(./assets/svg/owl-right-active.svg) no-repeat center !important;
    background-size: 40px !important;
}

.books-module-2.nav-green .aq-container .swiper .swiper-button-prev:hover:after {
    background: url(./assets/svg/owl-right-active-2.svg) no-repeat center !important;
    background-size: 40px !important;
}

.sticky-logo-container:hover {
    background: var(--main) !important;
}

.question-container-sec.sticky-on-this {
    padding-top: 13px;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 28;
}

.religion-container-11 div:last-child svg {
    width: 20px;
}

.little-svg-20 {
    scale: 1 !important;
    width: 24px !important;
}

.total-items {
    background: var(--main);
    padding: 4px 8px 0;
    color: #fff;
    font-weight: 200;
    font-family: numberFont;
    font-size: 13px;
    border-radius: 5px;
    margin-right: 2px;
    position: relative;
    top: -2px;
}

#sticky-after-visited {
    min-height: 108px;
}

#sticky-after-visited .question-container-sec.sticky-on-this .py-0 .px-sm-5.px-0.mx-sm-5.mx-0 {
    padding-top: 8px;
    height: 71px;
    overflow: hidden;
}

.download-information span {
    color: #FFFFFF99;
    font-size: 13px;
    font-weight: 200;
    line-height: 14px;
    text-align: left;
    padding-top: 4px;
}

.download-btn-g:hover .download-information span {
    color: #FFFFFFDF;
}

.old-site-modal {
    position: fixed;
    inset: 0;
    z-index: 10003;
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
}

.overlay-modal-old-site {
    position: fixed;
    inset: 0;
    background: #000000B2;
    z-index: 1;
}

.body-modal-old {
    position: fixed;
    background: #fff;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    z-index: 10;
    margin: auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 110px 0;
    border-radius: 12px;
}

.image-to-top {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -64px;
    width: 122px;
}

.image-to-top-container {
    height: 120px;
}

.text-body-modal {
    color: #535353;
    font-size: 17px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
}

.text-footer-modal {
    font-size: 14px;
    font-weight: lighter;
    line-height: 16px;
    text-align: center;
    color: #727272;
}

.text-green-only {
    color: var(--main);
    font-weight: bold;
}

.close-btn-modal {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
}

.close-btn-modal:hover svg path {
    stroke: #595656 !important;
}

.old-site-modal.close-old-site {
    opacity: 0;
    visibility: hidden;
}

.color-and-border-main {
    color: var(--main) !important;
    border-color: var(--main) !important;
}

.color-and-border-main:hover {
    color: #fff !important;
}

.basic-single .select__control {
    min-height: 40px;
}

.subject-horizontal-container {
    background: #0834341A;
    padding: 35px 0 40px;
}

.title-container-sub h2 {
    font-size: 18px;
    font-weight: bold;
    text-align: right;
    color: var(--main);
    margin: 0;
}

.all-book-sub-container {
    margin-top: 20px;
}

.all-book-sub-container a {
    background: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    color: #424242;
    transition: 0.3s;
}

.all-book-sub-container a span {
    color: var(--main);
    font-family: numberFont;
    font-weight: normal;
    font-size: 14px;
}

.all-book-sub-container a span {
    transition: 0.3s;
}

.all-book-sub-container a.select-this span,
.all-book-sub-container a:hover span {
    color: #fff;
}

.all-book-sub-container a.select-this,
.all-book-sub-container a:hover {
    background: var(--main);
    color: #fff;
}

.all-book-sub-container a {
    white-space: nowrap;
}

.all-book-sub-container {
    flex-wrap: wrap;
    gap: 13px;
}

.new-link-old {
    border: 1px solid #737373;
    color: #737373;
    padding: 7px 28px;
    border-radius: 8px;
    font-size: 16px;
    display: unset;
}

.alert-txt-mode {
    color: #990000 !important;
    font-size: 16px;
    font-weight: 300;
}

.mw-90 {
    max-width: 90%;
}

.c-not-allow {
    cursor: not-allowed;
}

.like:hover > svg path[stroke="#909090"],
.hover-this:hover svg path[stroke="#909090"] {
    stroke: var(--main)
}

.red-all span,
.red-all {
    color: var(--red) !important;
    border-color: var(--red) !important;
}

.red-all svg path[stroke="#919191"] {
    stroke: var(--red);
}

.red-all:hover span,
.red-all:hover {
    background-color: var(--red) !important;
    color: #fff !important;
    border-color: var(--red) !important;
}

.red-all svg path[stroke="#919191"] {
    stroke: var(--red);
}

.red-all:hover svg path[stroke="#919191"] {
    stroke: #fff;
}

.z-index-222 {
    z-index: 10000000000000000;
}

.number-font-page {
    color: #606060;
    font-size: 19px;
}

.number-font-page span {
    color: #949494;
}

.font-little {
    font-size: 13px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
    color: var(--main);
}

div:not(.select) .font-little:hover {
    background: #045E5E26;
}

.select .font-little {
    background: var(--main) !important;
    color: #fff;
}

.new-tab-more {
    background: #ececec;
    border-radius: 12px;
    width: 158px;
    text-align: center;
    padding-bottom: 8px;
}

.width-100-im {
    width: fit-content !important;
    min-width: fit-content !important;
}

ul.icon-container-share li svg path[stroke="#959595"],
ul.icon-container-share li svg path[stroke="#818181"],
.number-view ~ svg circle[stroke="#A5A5A5"],
.number-view ~ svg path[stroke="#A5A5A5"],
.bookmark ~ div svg path[stroke="#909090"],
.like svg path[stroke="#909090"],
.hover-this svg path[stroke="#909090"] {
    stroke: #2a2a2a
}

ul.icon-container-share li svg path[fill="#AFAFAF"] {
    fill: #797979;
}

.form-container-c-black div textarea::placeholder {
    color: #252525;
}

.color-fff a {
    color: rgba(255, 255, 255, 0.8) !important;
}

.ant-select-dropdown {
    z-index: 5555555555 !important;
}

.gsc-input {
    padding: 0 !important;
}

.gsc-search-button {
    margin: 0 !important;
}

.gsc-search-button.gsc-search-button-v2:visited,
.gsc-search-button.gsc-search-button-v2:checked,
.gsc-search-button.gsc-search-button-v2:hover,
.gsc-search-button.gsc-search-button-v2 {
    margin: 0 !important;
    background-color: transparent;
    border-color: #dbdbdb !important;
    height: 40px;
    border-left: 0 !important;
    padding: 0 9px;
    border-radius: 0 10px 10px 0;
}

.gsc-input-box {
    border-right: 0 !important;
    border-radius: 10px 0 0 10px;
}

.gsc-input {
    height: 38px;
}

.gsc-search-button-v2 svg {
    fill: transparent !important;
    background: url(./assets/svg/search-icon.svg) no-repeat center;
    background-size: 22px;
    width: 21px;
    height: 20px;
}

.gsc-control-cse.gsc-control-cse-en {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.gsib_a input {
    background: transparent !important;
}

.search-container-phone > div {
    background: transparent;
    border-color: transparent !important;
    width: 100%;
}

.gstl_50.gssb_c{
    display: none !important;
}

.gssb_e {
    display: none !important;
}

.ant-select-item[title="اسرائيل"] {
    display: none !important;
}

.old-site-container {
    width: 100%;
    display: block;
    background: var(--main);
    padding: 15px 20px;
}
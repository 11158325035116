@media (min-width: 900px) and (max-width: 22900px) {
    .right-logo-form {
        background: var(--red) url(./assets/svg/right-vec.svg) no-repeat right !important;
        width: 140px;
        height: 140px;
        display: block;
        position: absolute;
        background-size: 140px !important;
        top: -9px;
    }

    .question-container-sec.back-red-imp {
        position: relative;
        overflow: hidden;
    }

    .left-50 {
        float: left;
        width: 50%;
    }

    .border-1pm {
        border: 1px solid #e0e0e0;
        padding: 6px 17px 6px 7px;
        border-radius: 10px;
        min-width: 180px;
        position: relative;
        top: -7px;
    }

    .new-di {
        width: 100%;
        position: absolute;
        left: 0;
    }

    .z-100-i {
        z-index: 100;
    }
}

@media screen and (max-width: 900px) {
    html {
        width: 100%;
        overflow-x: hidden;
    }

    .hidden-phone {
        display: none !important;
    }

    .w-200 a .logo.position-absolute {
        width: 90px;
    }

    .w-200 {
        width: 100px;
    }

    .w-100-200 {
        width: calc(100% - 100px);
        padding-right: 5px;
    }

    .aq-header.pt-4-5 {
        padding-top: 11px;
        padding-bottom: 0;
    }

    .logo.position-absolute.mt-2 {
        width: 125px;
        position: relative;
        top: 20px;
        margin: 0 !important;
    }

    .hidden-desktop {
        display: block !important;
    }

    .login-container svg {
        margin-top: 13px;
    }

    .top-header {
        height: 75px;
    }

    .image-slider {
        height: 250px;
    }

    .slider-container {
        bottom: 5px;
        max-width: 90%;
    }

    .slider-container h2 {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 0;
    }

    .slider-container p {
        font-weight: 200;
        font-size: 14px;
        margin-bottom: 0;
        position: relative;
        top: -5px;
    }

    .slider-module .swiper .swiper-pagination {
        top: calc(100% - 35px);
        left: -5px;
        text-align: left;
        width: unset;
    }

    .slider-module .swiper .swiper-pagination .swiper-pagination-bullet {
        width: 7px;
        height: 7px;
    }

    .parallax-data {
        text-align: center;
        width: 50%;
        display: inline-block;
    }

    .parallax-data .data-container .disc {
        font-size: 15px;
    }

    .counter {
        font-size: 16px;
    }

    .parallax-container-icons.justify-content-center {
        display: block !important;
        position: absolute;
    }

    .parallax-container .aq-container div {
        aspect-ratio: 8 / 7 !important;
    }

    .parallax-container .aq-container div div {
        inset: 0 0 !important;
        background-repeat: no-repeat;
        background-size: 636px !important;
    }

    .parallax-container .aq-container {
        margin-left: 0;
        margin-right: 0;
    }

    .title-container.mt-5.pe-5:after,
    .left-line:after,
    .left-line:before,
    .left-line-1:after,
    .left-line-1:before,
    .left-line-2:after,
    .left-line-2:before,
    .left-line-4:after,
    .left-line-4:before,
    .left-line-3:after,
    .left-line-3:before {
        display: none;
    }

    .title-container h2 {
        font-size: 16px;
        font-weight: bolder;
        margin-bottom: 0;
    }

    .title-container p {
        font-size: 14px;
        position: relative;
        top: -4px;
        line-height: 16px;
    }

    .title-container.mt-5.pe-5.pb-4 {
        margin-top: 0 !important;
        padding-right: 12px !important;
        padding-bottom: 12px !important;
    }

    .left-logo-back .aq-container.pt-4 {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .left-logo-back .aq-container.pt-4 a {
        height: fit-content;
    }

    .left-logo-back .aq-container.pt-4 a svg {
        margin: 0 !important;
    }

    .people-world-container .container.d-flex.justify-content-between.align-items-center.py-5 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .people-world-container .container.d-flex.justify-content-between.align-items-center.py-5 .question-container svg {
        width: 98px;
        margin-right: 10px;
        margin-left: 2px !important;
    }

    .question-container-data .d-block.w-75 {
        width: 100% !important;
    }

    .question-container-data p {
        font-size: 14px;
    }

    .question-container-data small a,
    .question-container-data small {
        font-size: 13px;
        line-height: 19px;
    }

    .personal-information li a,
    .personal-information li {
        font-size: 13px;
    }

    .personal-information {
        align-items: center;
        justify-content: space-between;
        gap: unset;
    }

    .more-btn {
        padding: 6px 26px;
        font-size: 15px;
    }

    .aq-container.news-container.px-5 {
        padding: 0 !important;
    }

    .news-container .swiper .swiper-wrapper .swiper-slide {
        width: 275px !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: none !important;
    }

    .news-container .swiper .more-btn-container.d-flex.justify-content-end.mt--5 {
        justify-content: center !important;
    }

    .news-module {
        padding-bottom: 3rem;
    }

    .books-module .aq-container.pt-4.d-flex.justify-content-between.align-items-center {
        display: block !important;
    }

    .tab-aqua.navi button {
        padding: 15px 0;
        width: 60px;
        font-weight: 200;
        font-size: 13px;
    }

    .navi-container-media-2.float-left {
        float: unset !important;
        display: flex;
        justify-content: center;
        padding: 0 !important;
    }

    .tab-aqua.navi button:nth-child(2),
    .tab-aqua.navi button:nth-child(1) {
        width: 112px;
    }

    .navi button:nth-child(3):not(.inactive) ~ .animation {
        width: 64px;
        left: 232px;
        border-radius: 7px;
    }

    .navi button:nth-child(2):not(.inactive) ~ .animation {
        width: 116px;
        left: 120px;
        border-radius: 7px;
    }

    .navi button:nth-child(1):not(.inactive) ~ .animation {
        width: 120px;
        border-radius: 7px;
    }

    .aq-container.book-container.px-5 {
        padding: 0 !important;
        margin-left: 0;
        margin-top: 30px;
    }

    .navi-container-faq .navi button:not(.inactive) {
        font-weight: 400;
        transition: color 0.5s !important;
    }

    .book-container .swiper .more-btn-container.d-flex.justify-content-end.mt--52 {
        justify-content: center !important;
        position: relative;
        margin-left: 20px;
    }

    .book-container .swiper .swiper-wrapper .swiper-slide img {
        height: 259px;
    }

    .book-container .swiper .swiper-wrapper .swiper-slide {
        width: 184px;
        height: 380px;
    }

    .book-container .swiper .swiper-wrapper .swiper-slide > div {
        height: 240px;
    }

    .guided-module {
        background: #F9F9F9 !important;
    }

    .guided-module .aq-container.px-5.pt-4 {
        padding: 0 !important;
        margin-left: 12px;
    }

    .guided-module .aq-container.px-5.pt-4 .row .col-8 {
        width: 100%;
        padding-right: 15px !important;
        margin-top: 90px;
    }

    .user-desc-container {
        display: block;
        width: 237px;
        height: 167px;
        background-size: 233px;
        padding: 39px 23px 10px;
    }

    .col-md-7 .worldmap__figure-container {
        width: fit-content;
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .user-slider .swiper .swiper-wrapper .swiper-slide,
    .user-guide {
        width: 237px;
    }

    .guided-module .aq-container.px-5.pt-4 .row .col-8 .swiper .more-btn-container.d-flex.justify-content-end.mt--52 {
        position: absolute;
        left: 20px;
        right: 0;
        justify-content: center !important;
    }

    .user-slider .swiper {
        height: 315px;
    }

    .left-tab li.active:after {
        display: none !important;
    }

    .left-tab li:not(.active) h3 {
        display: none;
    }

    .left-tab li h3 {
        margin-bottom: 0 !important;
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        color: #fff;
        font-weight: 200;
        font-size: 14px;
    }

    .left-tab {
        display: flex;
        overflow-y: scroll;
        padding: 0 10px 0;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    .left-tab li:not(.active) svg,
    .left-tab li:not(.active) {
        margin-left: 0;
        transform: scale(1.2);
    }

    .user-name {
        font-size: 15px;
    }

    .user-desc-container-2 div {
        font-size: 14px;
    }


    .left-tab li.active svg circle:not(.active-white) {
        fill: transparent !important;
    }

    .left-tab li:not(:nth-child(3)).active svg circle.active-white,
    .left-tab li:not(:nth-child(3)).active svg path {
        stroke: #fff;
    }

    .left-tab li:not(.active) svg *[fill="#045E5E"] {
        fill: #EDEDED !important;
    }

    .left-tab li:not(.active) svg circle.active-white {
        stroke: #045E5E !important;
    }

    .left-tab li:not(:nth-child(3),.active) svg path[fill="white"] {
        fill: #045E5E !important;
    }

    .left-tab li:not(:nth-child(3),.active) svg path[stroke="white"] {
        stroke: #045E5E !important;
        fill: transparent;
    }

    .left-tab li:nth-child(3):not(.active) svg path[stroke="white"] {
        stroke: #045E5E !important;
        fill: transparent;
    }

    .left-tab li:nth-child(4) > svg > path[stroke="#fff"] {
        stroke: #045E5E !important;
    }

    .left-tab li.active:nth-child(4) > svg > path[stroke="#fff"] {
        stroke: #fff !important;
    }

    .left-tab li:not(:nth-child(3)).active svg path[fill="white"] {
        fill: #fff !important;
        stroke: transparent !important;
        transform: scale(1);
    }


    .left-tab li:not(:nth-child(3)).active svg circle.active-white,
    .left-tab li:not(:nth-child(3)).active svg path {
        stroke: #fff;
    }

    .left-tab li svg {
        transform: scale(1.3);
        display: inline-block;
    }

    .left-tab li:not(:nth-child(3), :nth-child(4)).active svg * {
        transform: scale(1.5);
    }

    .left-tab li:not(:nth-child(3), :nth-child(4)).active svg {
        position: relative;
        left: -12px;
        top: -6px;
    }

    .left-tab li:nth-child(4).active svg,
    .left-tab li:nth-child(3).active svg {
        width: 50px;
        margin-left: 5px;
        top: 3px;
        position: relative;
    }

    .left-tab li.active {
        margin-right: 10px;
        padding: 2px 0 0 4px;
        height: 42px;
    }

    .new-container-phone {
        padding: 0;
        position: absolute;
        left: 0;
        right: 0;
    }

    .country-module .aq-container.ps-5.pt-4 {
        padding: 0 !important;
    }

    .card-header-country {
        border-radius: 15px 15px 0 0;
    }

    .c-body-gray p {
        border-radius: 0 0 15px 15px;
    }

    .c-name span {
        font-size: 15px;
        font-weight: 400;
    }

    .c-left .number {
        font-size: 13px;
        font-weight: bolder;
    }

    .col-md-7.user-slider.p-0.pe-5 {
        padding: 0 !important;
    }

    .change-order-container .col-md-7 {
        order: 1;
    }

    .change-order-container .col-md-5 {
        order: 2;
    }

    .country-module .aq-container.p-1 {
        margin: 0;
    }

    .seminars-module .aq-container.px-5.pt-4 {
        padding: 0 !important;
    }

    .seminar-image {
        width: 290px;
    }

    .seminar-contianer {
        width: 290px;
    }

    .seminar-slider .swiper .swiper-wrapper .swiper-slide {
        width: 290px;
    }

    .speech-name {
        font-size: 16px;
    }

    .date {
        font-size: 12px !important;
    }

    .col-12.seminar-slider {
        padding-left: 0;
    }

    .seminars-module .aq-container.px-5.pt-4 {
        margin-left: 0;
    }

    .seminar-slider .swiper .more-btn-container.d-flex.justify-content-end.mt--52 {
        position: relative;
        left: 15px;
        justify-content: center !important;
    }

    .seminar-slider .swiper {
        height: 500px;
    }

    .seminars-module {
        background: #F3F3F3;
        padding-bottom: 46px;
    }

    .left-logo-back {
        background: #F7F7F7 !important;
    }

    .swiper-slide .slider-container-news .change-height {
        max-height: 500px;
    }

    .news-module .aq-container.news-container.px-5 {
        margin-left: 0;
    }

    .news-container .swiper .more-btn-container {
        left: 10px;
    }

    .left-tab::-webkit-scrollbar {
        width: 0;
    }

    .d-flex.my-4.footer-btn-container {
        display: block !important;
    }

    .d-flex.my-4.footer-btn-container .more-btn-container {
        margin-bottom: 17px;
    }

    .phone-center {
        text-align: center;
    }

    .module-footer {
        background: url(./assets/svg/logo/footer.svg) no-repeat #fff;
        background-size: 258px;
        background-position-x: right;
        background-position-y: bottom;
    }

    .module-footer .container .row .col-12.mt-5.pt-2 .d-flex.justify-content-center.justify-content-center {
        text-align: center;
    }

    .country-module {
        background: unset !important;
    }

    .slider-org-module.mb-5 {
        margin-top: 10px !important;
        margin-bottom: 20px !important;
    }

    .parallax-container-icons .parallax-data .data-container .counter {
        position: relative;
        top: -13px;
        font-weight: bold;
        font-size: 18px;
    }

    .parallax-data .data-container .disc {
        font-size: 16px;
        font-weight: bold;
        position: relative;
        top: -15px;
    }

    .login-container {
        position: relative;
        top: -5px;
    }

    .parallax-data svg {
        width: 47px;
    }

    .parallax-data.question-container svg {
        width: 44px;
        top: -4px;
        position: relative;
    }

    .title-container:before {
        width: 3px;
    }

    .left-logo-back .aq-container a svg {
        scale: 1.2;
    }

    .left-logo-back .aq-container a {
        padding: 8px 10px;
    }

    .personal-information li img {
        display: inline-block !important;
        border-radius: 6px;
    }

    .card-question .d-flex .right h3 {
        font-size: 15px;
    }

    .left-logo-back .question-answer-container.py-5.position-relative {
        padding-top: 10px !important;
    }

    .slider-container-news h3 {
        margin-bottom: 7px;
    }

    .left-tab li:not(.active):hover {
        background: unset !important;
        border-radius: unset !important;
    }

    .date-container-news.text-center svg {
        width: 17px;
    }

    .left-tab li.active {
        background: var(--main);
        cursor: default;
        border-radius: 25px;
    }

    .header-user-image {
        top: -43px;
    }

    .user-guide {
        margin-top: 49px;
    }

    .card-header-country {
        padding: 15px !important;
    }

    .c-body-gray p {
        padding: 10px 20px 20px;
    }

    .c-body-gray p {
        padding: 10px 20px 20px;
        font-size: 15px;
        line-height: 23px;
    }

    .btn-limit a {
        padding: 5px 21px;
        font-size: 14px;
    }

    .map-svg {
        width: 42px;
        right: -27px;
    }

    .slider-org-module a h3 {
        font-size: 14px;
    }

    .slider-org-module a .site-link {
        font-size: 14px;
    }

    .slider-org-module a {
        padding: 3px 22px 1px 10px;
    }

    .slider-org-module a .site-link {
        font-size: 14px;
        padding: 0 1px 0 0;
    }

    .slider-org-module a p svg {
        margin-right: 5px;
        width: 25px;
    }

    .footer-btn-container .more-btn-container a {
        font-size: 14px;
        padding: 8px 26px;
    }

    .footer-btn-container .more-btn-container:last-child a {
        padding: 7px 25px;
    }

    .red-link {
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
        padding: 0 42px !important;
    }

    .h-300 {
        padding-left: 1.5rem !important;
        font-size: 15px;
    }

    .footer-icons {
        display: flex !important;
        justify-content: space-evenly;
        background: #E9E9E9;
        border-radius: 10px;
        bottom: 10px;
        left: 20px;
        right: 20px;
        z-index: 10001;
        padding: 9px 0;
        box-shadow: 0px 0 8px 0px #0000002e;
    }

    /*button.border-0:active,*/
    /*button.border-0:focus {*/
    /*    border: 0 solid transparent !important;*/
    /*}*/
    .footer-option-btn {
        display: flex !important;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;
        bottom: 20px;
        top: auto;
        left: 20px;
        right: 20px;
        border-radius: 12px;
        z-index: 10001;
        background-color: #E9E9E9;
        padding: 8px 0;
        box-shadow: 0 0 7px 0px #00000069;
    }

    .search-container-phone {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        background: #e9e9e9;
        top: 0;
        padding: 18px;
        bottom: 0;
        border-radius: 35px;
        align-items: center;
        visibility: hidden;
    }

    .search-container-phone input {
        width: -webkit-fill-available;
        width: -moz-available;
        background-color: transparent;
        border: none;
        font-weight: 200;
        font-size: 14px;
    }

    .phone-menu {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10002;
        background: #fff;
        padding: 20px;
        visibility: hidden;
    }

    .phone-header-menu ul {
        list-style: none;
        padding: 0;
    }

    .phone-header-menu ul li {
        margin-top: 10px;
    }

    .phone-header-menu ul li button {
        background-color: transparent;
        border: none;
        padding: 0;
    }

    .phone-header-menu ul li button > svg,
    .phone-header-menu ul li a > svg {
        width: 30px;
        height: 30px;
        margin-left: 7px;
    }


    .phone-header-menu ul li button span,
    .phone-header-menu ul li a span {
        color: #9F9F9F;
        font-size: 14px;
        font-weight: 200;
    }

    .phone-menu-footer {
        font-size: 11px;
        line-height: 12px;
        text-align: justify;
        color: var(--red);
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 23px;
        text-align: justify;
    }

    .w-h-33 {
        width: 30px;
        height: 30px;
    }

    .phone-menu.show {
        visibility: visible;
    }

    .search-container-phone.show {
        visibility: visible;
    }

    .width-0-phone {
        width: 0 !important;
    }

    .filter-container-mt {
        padding-top: 22px;
        padding-bottom: 20px;
        margin: 0 20px;
        border-radius: 16px;
    }

    .show-o-container {
        position: absolute;
        right: -28px;
        width: 35px;
        top: -9px;
    }

    .faq-title {
        font-size: 15px;
        line-height: 19px;
        color: #FFFFFF;
    }

    .question-container-data small {
        font-size: 13px !important;
    }

    .w-input {
        width: 43px;
        margin: 0;
        transition: width 0.4s;
    }

    .w-input.open-s {
        width: 256px;
    }


    .w-input .search-text-box-1 {
        padding: 0 7px 0 0;
        z-index: 1;
        margin: 0;
    }

    .w-input.open-s .search-text-box-1,
    .w-input.open-s .search-text-box-1::placeholder {
        font-size: 13px;
        color: #FFFFFFCC !important;
    }

    .w-input.open-s .search-text-box-1 {
        animation: p-left linear 0.4s forwards;
    }

    @keyframes p-left {
        from {
            padding-left: 0px;
        }
        to {
            padding-left: 30px;
        }
    }

    .w-input .search-text-box-1,
    .w-input .search-text-box-1::placeholder {
        font-size: 13px;
        color: transparent;
    }

    .search-sub-menu {
        display: flex;
        gap: 10px;
        overflow: scroll;
        min-width: 100%;
        width: fit-content;
        white-space: nowrap;
        overflow: scroll;
    }

    form.botton-submit1 {
        overflow-x: scroll;
        padding-right: 25px;
        padding-left: 25px;
    }

    form.botton-submit1::-webkit-scrollbar {
        height: 0;
    }

    .filter-container,
    .width-100-phone {
        width: 100%;
    }

    .filter-container-mt .aq-container {
        margin: 0;
    }

    .filter-container .aq-container {
        margin: 0 66px 0 25px;
    }

    .width-100-phone {
        padding: 0;
    }

    .d-flex-imp {
        display: flex !important;
    }


    .select-by-filter.hidden-desktop.d-flex-imp {
        height: 36px;
    }

    .select-by-filter.hidden-desktop.d-flex-imp p {
        font-weight: 100;
        padding-right: 12px !important;
    }

    .select-by-filter svg {
        width: 16px;
    }

    .close-input {
        position: absolute;
        left: 7px;
        top: 5px;
        width: 22px;
        z-index: 100;
        opacity: 0;
        visibility: hidden;
        transition: 0.4s;
    }

    .w-input.open-s .close-input {
        visibility: visible;
        opacity: 1;
    }

    .change-height {
        max-height: unset !important;
    }

    .width-100-phone-2 {
        width: auto;
        margin: 15px 25px;
    }

    .t-62 {
        top: -62px;
    }

    .close-input {
        display: block;
    }

    .back-red-imp-phone-w {
        background-color: #fff !important;
    }

    .back-red-imp-phone-w .back-red-imp {
        border-radius: 18px;
    }

    .mt--58 {
        top: -58px;
    }

    .user-name-container-line-white:after,
    .green-border:after,
    .border-red-and-dashed .col-10:after, .border-red-and-dashed .col-8:after, .border-red-and-dashed .col-7:after, .border-red-and-dashed .col-6:after {
        display: none;
    }

    .like-and-bookmark {
        margin-top: 0;
    }

    .user-name-container {
        display: block;
    }

    .like-and-bookmark.justify-content-end.gap-2 {
        justify-content: center !important;
        margin-bottom: 0;
        margin-top: 38px;
    }

    .icon-container-share {
        transform: translateY(200%);
    }

    .icon-container-share-2 {
        transform: translateY(100%) !important;
    }

    .phone-header-icon {
        display: inline-block;
    }

    .send-answer-phone-w {
        background-color: #fff;
    }

    .send-answer-phone-w > div > div > div > div {
        background-color: var(--main);
        border-radius: 12px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .book-container-phone .swiper .swiper-wrapper .swiper-slide {
        width: 171px;
        height: 305px;
    }

    .aq-container.book-container-phone.px-5 {
        margin-right: 0;
        margin-top: 18px;
    }

    .phone-m-x-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .phone-m-t-2 {
        margin-top: 20px;
    }

    .seminar-slider-23 .swiper {
        height: 450px;
    }

    .phone-nav-container > div > div {
        width: 100%;
    }

    .phone-nav-container > div > div .tab-aqua.navi {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        border-radius: 0 0 10px 10px;
    }

    .border-rad-top-10 {
        border-radius: 10px 10px 0 0 !important;
        overflow: hidden;
    }

    .tab-aqua.navi a {
        width: unset !important;
        font-size: 12px;
        font-weight: 100;
        padding: 5px 5px 19px 5px;
        margin-top: 5px;
        border-radius: 5px;
    }

    .tab-aqua.navi a ~ .animation {
        display: none !important;
    }

    .navi a:not(.inactive) {
        background-color: #fff;
    }

    .phone-nav-container > div {
        margin-bottom: 40px !important;
    }

    .book-image-container-c-2 img, .book-image-container-c-2 {
        max-width: 186px;
        height: 218px;
    }

    .phone-nav-container .container .row {
        margin: 0 !important;
    }

    .phone-nav-container .container .row .col-6 .book-data-container a h3 {
        font-size: 16px;
    }

    .phone-nav-container .container .row .col-6 .book-data-container a p {
        font-size: 14px;
    }

    .phone-w-100 {
        width: 100%;
    }

    .header-user-image.phone-60-t {
        top: -60px;
    }

    .hidden-desktop-flex {
        display: flex !important;
    }

    .like > svg {
        width: 17px !important;
        margin-right: 5px;
    }

    .bookmark a svg {
        width: 22px !important;
    }

    .hidden-desktop-flex .d-flex svg {
        width: 25px;
    }

    .text-page-title {
        font-size: 16px;
    }

    .value a,
    .value,
    .key {
        font-size: 14px;
    }

    .phone-inline {
        display: inline-block !important;
    }

    .mt-phone-2 {
        margin-top: 5px;
    }

    .green-border .d-sm-flex .w-34,
    .green-border .d-sm-flex .w-34 svg {
        width: 30px;
        height: 30px;
    }

    .btn-not-fill-tag {
        padding: 3px 21px;
        border-radius: 8px;
    }

    .phone-inline {
        display: inline-block !important;
        font-size: 15px;
        font-weight: unset;
    }

    .phone-modal {
        position: fixed;
        z-index: 10001;
        background: #fff;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 44px 20px 15px;
        border-radius: 15px 15px 0 0;
        min-height: 50vh;
        max-height: 200px;
        overflow: hidden;
        transition: 0.5s;
    }

    .close-modal .phone-modal {
        bottom: -100%;
    }

    .phone-modal-container {
        z-index: 10001;
    }

    .modal-option {
        font-size: 14px;
        color: #7C7C7C;
        font-weight: 200;
    }

    .modal-option-container .modal-option {
        margin-bottom: 7px !important;
    }

    .close-phone-modal {
        position: absolute;
        left: 20px;
        top: 10px;
    }

    .close-phone-modal svg {
        width: 20px;
        height: 20px;
    }

    .modal-title-green {
        font-size: 16px;
        font-weight: bold;
        line-height: 15px;
        color: var(--main);
    }

    .phone-modal .modal-title-green {
        margin-bottom: 10px !important;
    }

    .overlay-phone-modal {
        z-index: 9999;
        background: #000000;
        opacity: 0.8;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: 1.5s;
        visibility: visible;
    }

    .close-modal .overlay-phone-modal {
        opacity: 0;
        visibility: hidden;
        z-index: 9999;
    }

    .modal-option.select {
        color: var(--main);
    }

    .modal-option-container {
        overflow-y: scroll;
        overflow-x: hidden;
        height: calc(100vh - 402px);
    }

    .not-funded-filter {
        font-size: 14px;
    }

    .phone-modal-container {
        display: block;
    }

    .w-h-55 {
        width: 70px;
        height: 70px;
    }

    .phone-m-y-5 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .like-and-bookmark.justify-content-end.gap-2.left-imp {
        justify-content: left !important;
        position: absolute;
        left: 11px;
        padding-top: 25px;
    }

    .bookmark a svg.w-17-imp {
        width: 15px !important;
    }

    .top-4 {
        top: -56px !important;
        left: 0;
    }

    .top-5 {
        top: -78px !important;
        left: 0;
    }

    .w-22-imp {
        width: 22px !important;
    }

    .like .w-20-imp-2 {
        width: 20px !important;
    }

    .phone-comment-title {
        color: #656565 !important;
        font-size: 16px !important;
        font-weight: bold !important;
        padding-top: 3px;
    }

    .answer-container-pad-right:before {
        display: none;
    }

    .answer-container-pad-right {
        padding-right: 10px;
        padding-left: 10px;
    }

    .comment-body {
        font-size: 14px;
        line-height: 23px;
    }

    .bg-main {
        padding: 15px;
        border-radius: 12px;
    }

    .color-767676 {
        font-size: 14px;
    }

    .phone-mx-0-imp {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .header-user-image-59 {
        top: -59px;
    }

    .user-guide-72 {
        margin-top: 72px;
    }

    .image-text-page-container img, .image-text-page-container video {
        height: 180px;
    }

    .phone-flex {
        display: flex !important;
    }

    .news-container-little .swiper .swiper-wrapper .swiper-slide,
    .news-container-little .swiper .swiper-wrapper .swiper-slide a,
    .news-container-little .swiper .swiper-wrapper .swiper-slide img {
        width: 220px !important;
    }

    .news-container-little .swiper {
        height: 370px;
    }

    .phone-nav-container > div > div .navi3,
    .navi-container-faq .navi3 {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        border-radius: 0 0 10px 10px;
        background: var(--red);
    }

    .statistics .text-number {
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
    }

    .statistics .text {
        font-size: 12px;
        font-weight: 100;
        text-align: center;
        color: #FFFFFFA6;
    }

    .phone-nav-container > div.mb-phone-0 {
        margin-bottom: 0 !important;
    }

    .question-answer-container.py-sm-5.py-0.position-relative.phone-m-9 {
        padding: 0 9px;
    }

    .height-100-sc div div .w-100.mt-3 {
        padding-right: 0 !important;
    }

    .height-100-sc > div > div {
        height: fit-content;
        position: relative !important;
    }

    .height-100-sc {
        height: unset !important;
        max-height: 500px;
        overflow: scroll;
    }

    .back-f2 {
        background: #F8F8F8;
    }

    .height-fit-c {
        height: fit-content;
    }

    .w-h-18 {
        width: 18px;
        height: 18px;
    }

    .border-main-2 {
        border-color: var(--main);
    }

    .alphabet-subjects-container.position-absolute {
        position: fixed !important;
        left: 0;
        right: 0;
        width: unset;
        bottom: 0;
        height: 600px;
        overflow: hidden;
        top: auto;
        z-index: 10002;
        border-radius: 20px 20px 0 0;
    }

    .alphabet-subjects-container div ul li {
        min-width: 30px;
        display: table-caption;
    }

    .d-sm-flex .d-block.width-100-phone.overflow-scroll {
        padding-bottom: 9px;
    }

    .d-sm-flex .d-block.width-100-phone.overflow-scroll::-webkit-scrollbar {
        height: 0;
    }

    ul.top-ul > li {
        border: 1px solid var(--main);
    }

    .alphabet-subjects-container div ul li.disable {
        border: 1px solid #045E5E66;
    }

    .subject-container-div li {
        display: inline;
        margin-top: 10px;
    }

    .subject-container-div {
        margin-top: 10px;
        padding: 6px 0;
    }

    .subject-container-div > li:hover {
        color: #7C7C7C !important;
        background-color: transparent !important;
    }

    .phone-m-t-5 {
        margin-top: 20px;
    }

    .phone-m-t-4 {
        margin-top: 11px;
    }

    .filter-container > .botton-submit1.question-container-data {
        margin-top: 0;
    }

    .modal-phone-22 {
        position: fixed;
        z-index: 10001;
        background: #fff;
        bottom: 0;
        border-radius: 25px 25px 0 0;
        text-align: justify;
        font-weight: 100;
        padding: 17px 20px;
        left: 0;
        right: 0;
        min-height: 50vh;
    }

    .btn-not-fill:not(.selected):hover {
        border-color: var(--main) !important;
    }

    .phone-center-imp {
        text-align: center !important;
    }

    .px-custom {
        padding-right: 9px;
        padding-left: 9px;
    }

    .personal-information li:first-child {
        max-width: 88px;
    }

    .personal-information li:nth-child(3) {
        max-width: 157px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .phone-scroll {
        max-width: 100vw;
        overflow: scroll;
    }

    .phone-scroll-2 {
        display: flex;
        gap: 8px;
        width: fit-content;
    }

    .phone-m-0 {
        margin-bottom: 0 !important;
    }

    .header-user-image-container:after {
        width: 104px;
        height: 100px;
        top: -38px;
    }

    .header-user-image {
        width: 65px;
        height: 65px;
        top: -48px;
    }

    .user-desc-container.back-transparent {
        height: 166px;
    }

    .overlay-play {
        top: 67px;
    }

    .search-input-group-2 {
        padding: 0 1px !important;
    }

    .parallax-data.question-container svg {
        width: 54px !important;
        top: -7px !important;
    }

    .parallax-data.submitted-answer-container svg {
        width: 61px !important;
        top: -4px !important;
    }

    .parallax-data.submitted-answer-container svg {
        width: 56px !important;
        top: -4px !important;
    }

    .parallax-data.submitted-answer-container svg.w-70 {
        width: 57px !important;
        top: 0 !important;
    }

    .w-66-h {
        width: 58px !important;
        top: -3px !important;
    }

    .question-container-data small {
        font-size: 13px;
        line-height: 16px;
        margin-top: 3px;
    }

    .d-flex-phone-desc {
        flex-flow: row-reverse;
        text-align: left;
        justify-content: end !important;
        position: absolute;
        left: 17px;
        top: -35px;
    }

    .transform-unset {
        transform: unset !important;
        left: 0;
    }

    .d-block-phone {
        display: block !important;
        font-size: 14px !important;
        position: relative;
        top: -27px;
        right: -5px;
    }

    .d-block-phone img {
        position: relative;
        top: -2px !important;
        width: 17px !important;
        border-radius: 35px !important;
    }

    .user-name-12 {
        font-size: 16px;
        color: #585858;
        position: relative;
        font-weight: bold;
        top: -8px;
    }

    .phone-p-0 {
        padding: 0;
    }

    .pe-phone-c-2 {
        padding-right: 40px;
        position: absolute;
        left: 10px;
        right: 14px;
        margin-top: -36px;
    }

    .w-34-imp svg,
    .w-34-imp {
        width: 34px !important;
        height: 34px !important;
    }

    .card-question .d-flex.justify-content-between {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card-question .d-flex.justify-content-between .right {
        width: 100%;
    }

    .social-container-filter, .social-container-filter svg,
    .social-container-ul-filter li button div svg {
        width: 55px;
        height: 55px;
    }

    .social-container-ul-filter li button .number-bold {
        font-size: 15px;
        line-height: 17px;
    }

    .social-container-ul-filter {
        text-align: center;
    }

    .w-100-imp {
        width: 100% !important;
    }

    .w-100-imp .input-group.search-input-group.position-relative {
        padding: 0 !important;
    }

    .w-100-imp .input-group.search-input-group.position-relative input {
        margin: 0 !important;
    }

    .phone-green {
        background: var(--main);
        color: #fff;
        border-radius: 20px;
        margin: 0 19px;
        padding: 10px 15px 15px !important;
        width: auto;
    }

    .phone-green .row h2 {
        font-size: 17px;
        font-weight: lighter;
    }

    .phone-green * {
        color: #fff;
    }

    .subject-container-bg-transparent {
        background-color: transparent !important;
    }

    .mt-phone-4 {
        margin-top: 20px;
    }

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }


    .w-55-imp svg,
    .w-55-imp,
    .w-55 svg,
    .w-55 {
        width: 34px !important;
        height: 34px !important;
    }

    .font-size-12-pos {
        font-size: 17px;
        color: var(--main);

    }

    .phone-w-100-imp {
        width: 100% !important;
    }

    .phone-w-100-imp span {
        width: fit-content;
        display: inline-block !important;
        padding-right: 10px;
        font-weight: bold;
    }

    .site-name-custom {
        font-size: 14px;
    }

    .p-t-0-phone {
        padding-top: 0 !important;
    }

    .phone-scroll {
        text-wrap: nowrap;
    }

    .top-12-px {
        top: 12px;
    }

    .question-container-sec.mt-phone-4 .aq-container.back-light-g.mb-3.pb-1 {
        padding: 3px 0 20px !important;
    }

    .d-inline-block.bookmark-this svg {
        width: 30px !important;
    }

    .custom-info-icon {
        left: 35px;
        top: 50%;
        transform: translateY(-50%);
    }

    .back-light-g:nth-child(even) .px-1 .px-1 .col-12 .green-border .d-sm-flex .phone-w-100-imp span {
        color: var(--red);
    }

    .back-light-g:nth-child(even) .px-1 .px-1 .col-12 .green-border .d-sm-flex .phone-w-100-imp svg path[fill="#045E5E"],
    .back-light-g:nth-child(even) .px-1 .px-1 .col-12 .green-border .d-sm-flex .phone-w-100-imp svg circle[fill="#045E5E"],
    .back-light-g:nth-child(even) .px-1 .px-1 .col-12 .green-border .d-sm-flex .phone-w-100-imp svg path[fill="#045E5E"],
    .back-light-g:nth-child(even) .px-1 .px-1 .col-12 .green-border .d-sm-flex .phone-w-100-imp svg circle[fill="#045E5E"] {
        fill: var(--red)
    }

    .top-28 {
        top: -28px;
    }

    .form-container--3 {
        padding: 10px 20px 30px 20px;
    }

    .d-inline-flex.me-5.align-items-center {
        position: relative;
        top: 7px;
    }


    .seminar-slider-101 .swiper {
        height: 288px !important;
    }

    .seminar-slider-102 .swiper {
        height: 260px !important;
    }

    .f-16-imp {
        font-size: 16px !important;
    }

    .p-7 {
        padding: 0 7px 0 0 !important;
    }

    .w-100-9 {
        width: calc(100% - 9px) !important;
    }

    .f-14-phone {
        font-size: 12px;
        margin: 10px 43px 0 !important;
        text-align: center;
    }

    .phone-m-0 {
        margin: 0 !important;
    }

    .p-phone-0 {
        padding: 0 !important;
    }

    .mt-33 {
        margin-bottom: 7px;
        margin-top: 23px;
    }

    .mb-33 {
        margin-bottom: 7px;
    }

    .news-container-little-big .swiper .swiper-wrapper .swiper-slide img, .news-container-little-big .swiper .swiper-wrapper .swiper-slide {
        width: 220px !important;
        height: 300px !important;
    }

    .news-container .swiper .more-btn-container.d-flex.justify-content-end.justify-content-sm-end.mt--5 {
        justify-content: end !important;
        left: 0 !important;
    }

    .user-slider-2 .swiper {
        height: 300px;
    }

    .user-slider-h-5 .swiper {
        height: 337px !important;
    }

    .user-slider-h-5 .swiper .swiper-wrapper .swiper-slide,
    .user-slider-h-5 .swiper .swiper-wrapper .swiper-slide .user-guide .user-desc-container img,
    .user-slider-h-5 .swiper .swiper-wrapper .swiper-slide .user-guide {
        width: 237px !important;
    }

    .seminar-slider .swiper .more-btn-container.d-flex.justify-content-sm-end.justify-content-end.mt--52 {
        justify-content: end !important;
        left: 0 !important;
    }

    .seminar-slider-22 .swiper {
        height: 285px;
    }

    .seminar-slider-22 .swiper .more-btn-container.d-flex.justify-content-end.mt--52 {
        justify-content: end !important;
        left: 0 !important;
    }

    .seminar-slider-33 .swiper .swiper-wrapper .swiper-slide {
        width: 110px;
    }

    .navi33 a {
        font-size: 13px !important;
    }

    .position-relative-top-0 {
        top: 0 !important;
    }

    .navi33 a.selected {
        border-radius: 7px;
    }

    .navi33 {
        padding: 7px 7px;
    }

    .font-size-12-phone {
        font-size: 15px;
    }

    .c-list-container-2 {
        position: fixed;
        top: unset;
        width: 100%;
        background: #F8F8F8;
        visibility: hidden;
        opacity: 0;
        transition: 0.4s;
        padding-bottom: 17px;
        box-shadow: 0 0 7px 0 #0000002e;
        bottom: 0;
        left: 0;
        border-radius: 10px 10px 0 0;
        right: 0;
        z-index: 10000000;
    }

    .input-group.search-input-group.phone-p-0.search-input-group-3.position-relative {
        padding: 0 0 0 1px !important;
    }

    .overlay-c.open {
        background: #00000080;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999999;
        visibility: visible;
        opacity: 1;
    }

    .overlay-c:not(.open) {
        visibility: hidden;
        opacity: 0;
    }

    .force-first-g.m-b-10:first-child,
    .m-b-10 {
        border-radius: 20px !important;
        margin: 10px !important;
    }

    .force-first-g.m-b-10 div .row .col-12 .green-border.z-index-2-after .d-flex .header-title h2 {
        font-size: 16px;
        font-weight: 200;
    }

    .d-flex.justify-content-center.position-relative-top-0.hidden-desktop {
        margin: auto !important;
    }

    .force-first-g.m-b-10:first-child div .row .col-12 .green-border.z-index-2-after div.d-sm-flex.d-block.pt-4 > span.mt-1-5.color-main-2.pt-1.phone-inline.hidden-desktop {
        color: #fff;
    }

    .overlay-play-2 {
        transform: translate(50%, -12px);
        background-size: 47px;
    }

    .text-custom-red .date {
        color: var(--red);
    }

    .text-custom-red {
        position: absolute;
        right: 0;
        width: fit-content;
    }

    .phone-m-r-7 {
        margin-right: 7px;
    }

    .left-15 {
        left: auto;
        top: -204px;
        z-index: 100;
        right: 24px;
    }

    .media-changer:not(.selected) svg path[stroke="#a59e9e"] {
        stroke: #045E5E4D

    }

    .media-changer:not(.selected) svg path[fill="#D9D9D90"] {
        fill: #045E5E4D;
    }

    .media-changer:not(.selected) svg path[stroke="#A6A6A6"] {
        stroke: #fff
    }

    .media-changer svg {
        width: 37px;
    }

    .color-red-ma {
        color: var(--red);
    }

    .user-name-container-line-white-b:after {
        display: none;
    }

    .value.text-white-60 {
        padding-right: 8px !important;
        text-align: left;
    }

    .book-value-container-3 tbody tr th, .book-value-container-3 tbody tr td {
        padding-top: 8px;
    }

    .book-value-container.book-value-container-3 {
        width: 100%;
    }

    .book-value-container-3 tbody tr th {
        font-weight: bold;
    }


    .f-14-phone-c {
        font-size: 14px !important;
        color: #46464680;
        font-weight: bold;
    }

    .border-radius-m10 {
        border-radius: 10px;
        margin: 0 10px;
    }

    .ul-ir-r {
        text-align: center;
        margin: 0 !important;
    }

    .ul-ir-r li {
        margin-bottom: 10px;
    }

    .ul-ir-r li,
    .ul-ir-r li a {
        margin-left: 2px;
        font-size: 14px;
    }

    .ul-ir-r li a svg {
        width: 24px !important;
    }

    .ul-ir-r li a .width-3 {
        width: 20px !important;
    }

    .phone-jus-center {
        justify-content: center;
    }

    .phone-m-b-2 {
        margin-bottom: 5px;
    }

    .phone-m-auto {
        margin: auto !important;
        width: 49px !important;
        height: 49px !important;
    }

    .my-phone-padding {
        padding: 35px 0 35px 0 !important;
    }

    .left-top-phone {
        left: -26px;
        top: 14px;
    }

    .hover-svg-f {
        position: relative !important;
        margin-top: 32px;
        padding: 6px 17px;
        border-radius: 7px;
    }

    .select-pos {
        font-size: 14px;
    }

    .hover-svg-f.color-white:hover {
        border: 1px solid transparent !important;
    }

    .forms-module {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 0;
        z-index: 100001;
        display: block !important;
        padding: 0 !important;
    }

    .forms-module .form-body {
        padding: 0;
        text-align: center;
        margin: 0 25px;
        overflow: scroll;
        height: calc(100vh - 108px);
        padding-bottom: 40px;
    }

    .forms-module .form-body .form-body-item div:not(.ant-select-selector) {
        text-align: center !important;
    }

    .forms-module .header-tab {
        padding: 0;
        width: -webkit-fill-available;
        height: fit-content;
        overflow: scroll;
        margin: 10px;
        min-width: unset !important;
        border-radius: 8px;
        margin-top: 50px;
    }

    .forms-module .header-tab ul {
        display: flex;
        width: fit-content;
        border-radius: 0 !important;
    }

    .forms-module .header-tab ul li {
        white-space: nowrap;
        margin: 6px 0;
    }

    .forms-module .header-tab ul li button {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 14px;
        padding: 7px 7px;
        border-radius: 6px;
    }

    .form-title {
        font-size: 16px;
    }

    .form-disc {
        font-size: 14px;
    }

    .checkbox-pop + label span {
        position: relative;
        left: 0;
    }

    .phone-m-t-1 {
        margin-top: 10px;
    }

    .login-modal {
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        transform: unset;
        bottom: 0;
        overflow-y: scroll;
        z-index: 100001;
        border-radius: 0 !important;
    }

    .image-uploader svg {
        width: 85px;
        position: relative;
        top: -28px;
        transform: unset !important;
    }

    .image-uploader {
        display: flex;
        justify-content: center;
        position: absolute;
        height: 98px;
        margin: 0 auto;
        left: unset;
        right: unset;
    }

    .upload__image-wrapper {
        display: flex;
        justify-content: center;
    }

    .top-center {
        top: 45px;
        height: 48px;
        display: flex;
        align-items: center;
        transform: unset;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        position: relative !important;
    }

    .top-center svg {
        width: 110px;
    }

    .image-container img {
        width: 170px;
        height: 170px;
        border-radius: 100%;
        border: 6px solid #EFEFEF;
        object-fit: cover;
        margin-top: 30px;
    }

    .width-height-150 {
        width: 105px !important;
        height: 105px !important;
        margin-bottom: 20px;
        background-color: #fff;
    }

    .user-desc-container-2 {
        margin: 12px 0 8px;
    }

    .phone-menu.hidden-desktop .phone-header-menu.d-flex.justify-content-center ul li {
        position: relative;
    }

    .phone-menu.hidden-desktop .phone-header-menu.d-flex.justify-content-center ul li .hid-title {
        position: absolute;
        transform: translateX(100%);
        padding: 4px 0 0 4px;
        visibility: hidden;
        opacity: 0;
        transition: 0.4s;
        font-size: 11px;
    }

    .phone-menu.hidden-desktop .phone-header-menu.d-flex.justify-content-center ul li.active .hid-title {
        visibility: visible;
        opacity: 1;
    }

    .phone-menu.hidden-desktop .phone-header-menu.d-flex.justify-content-center ul li .hid-title svg {
        position: relative;
        left: 6px;
        top: -1px;
    }

    li .open-sub-menu .phone-child-link {
        position: absolute;
        background: #fff;
        text-align: right;
        right: 32px;
        left: -61px;
        z-index: 1000;
        bottom: 19px;
        transform: translateY(50%);
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
    }

    li.active .open-sub-menu .phone-child-link {
        opacity: 1;
        visibility: visible;
    }

    li .open-sub-menu .phone-child-link a {
        font-weight: lighter;
        padding-bottom: 0 !important;
        color: var(--main);
        padding-right: 8px !important;
    }

    .phone-menu.hidden-desktop .phone-header-menu.d-flex.justify-content-center ul li:not(:first-child).disable button svg circle[fill="#045E5E"],
    .phone-menu.hidden-desktop .phone-header-menu.d-flex.justify-content-center ul li:not(:first-child).disable button svg rect[fill="#045E5E"],
    .phone-menu.hidden-desktop .phone-header-menu.d-flex.justify-content-center ul li:not(:first-child).disable a svg circle[fill="#045E5E"],
    .phone-menu.hidden-desktop .phone-header-menu.d-flex.justify-content-center ul li:not(:first-child).disable a svg rect[fill="#045E5E"] {
        fill: #CECECE;
    }

    .phone-menu.hidden-desktop .phone-header-menu.d-flex.justify-content-center ul li:not(:first-child).disable button span,
    .phone-menu.hidden-desktop .phone-header-menu.d-flex.justify-content-center ul li:not(:first-child).disable a span {
        opacity: 0;
    }

    .send-to-bottom {
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
    }

    .h-302 {
        height: 62px;
    }

    .forms-module {
        background: #fff !important;
    }

    .login-container svg {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .max-width-30 {
        max-width: 100% !important;
    }

    .phone-mt-32 {
        margin-top: 110px;
    }

    .phone-mt-32 .upload__image-wrapper img {
        width: 120px;
        height: 120px;
    }

    .name-input-user.max-width-30 {
        margin-top: 4.3rem !important;
    }

    .phone-px-2 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .min-wid-phone-123 {
        min-width: 123px;
    }

    .gap-0-phone {
        gap: 0;
    }

    .select-by-filter.hidden-desktop.d-flex-imp:nth-last-child(2) {
        background-color: rgba(255, 255, 255, 0.9) !important;
    }

    .select-by-filter.hidden-desktop.d-flex-imp:nth-last-child(2) p {
        color: var(--main) !important;
    }

    .selected-back-w {
        background-color: rgb(231 235 235) !important;
    }

    .selected-back-w p {
        color: var(--main) !important;
    }

    .selected-back-w svg path {
        stroke: var(--main) !important;
    }

    .login-container svg.mt-13 {
        margin-top: 13px !important;
    }

    .modal-faq-cat {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        z-index: 10010;
    }

    .all-subjects-faq-list {
        height: 100%;
        padding: 17px 8px;
    }

    .top-32 {
        bottom: 92px;
    }

    .floating-btn {
        right: 20px;
    }

    .big-form {
        padding: 0 !important;
    }

    .big-form .search-sub-menu {
        width: 100% !important;
    }

    .big-form .search-sub-menu .input-group.search-input-group {
        padding: 0 !important;
        margin-left: 1px;
    }

    .all-subjects-faq-list > div > div {
        padding-bottom: 200px;
    }

    .sub-box-close {
        position: absolute;
        top: 4px;
        left: 7px;
    }

    .phone-w-100-imp {
        width: 100% !important;
        margin: 15px 0 !important;
    }

    .phone-p-0-imp {
        padding: 0 !important;
    }

    .left-tab li.active {
        top: 2px;
    }

    .pt-phone-29 {
        padding-top: 29px;
    }

    .phone-w-300 {
        width: 290px !important;
        margin-left: auto;
        margin-right: auto;
    }

    .phone-w-250 {
        width: 250px !important;
        margin: 0 auto;
    }

    .phone-mr-3 {
        margin-right: 40px !important;
    }

    .radius-b-0 {
        border-radius: 16px 16px 0 0;
    }

    .phone-mb-3 {
        margin-bottom: 40px;
    }

    .link-to-home-page.link-to-home-page-f {
        display: block;
        margin-bottom: 0;
        margin-left: 20px;
    }

    .content-p-404-last.mt-5 {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 15px;
        justify-content: center;
    }

    .content-p-404-last.mt-5 .link-to-home-page.link-to-home-page-f {
        margin: 0 !important;
    }

    .link-to-home-page.me-3 {
        margin-right: 0 !important;
    }

    .section-footer-page-404 {
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .footer-page-404.d-flex {
        margin: auto;
        justify-content: center;
    }

    .header-title {
        color: var(--main);
    }

    .top-21 {
        top: -21px !important;
    }

    .user-name-container-mt-15 {
        margin-top: 15px !important;
    }

    .phone-ini {
        width: 100%;
        overflow-x: scroll;
    }

    .phone-ini-2 {
        display: flex;
        gap: 13px;
        width: fit-content;
    }

    .filter-container-mt {
        overflow: hidden;
    }

    .phone-modal div.modal-option {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .pr-21px {
        padding: 0 21px;
    }

    .navi {
        height: 50px;
    }

    .m-auto-2-s {
        margin: 10px 0 15px 0 !important;
    }

    .news-container-little-phone .swiper {
        height: 302px;
    }

    .transform-0 {
        transform: translateY(3px);
        left: 0;
    }

    .change-close-space {
        left: 0 !important;
        top: 0 !important;
    }

    .d-flex.justify-content-center.user-desc-container-2.mb-3 .c-name {
        max-width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .alphabet-subjects-container .d-sm-flex .subject-container-div li {
        width: 100% !important;
    }

    .alphabet-subjects-container .d-sm-flex .subject-container-div li.select .green-subject {
        color: #fff;
    }

    .col-sm-7.col-12.position-relative .green-border .d-flex.mt-4 .header-title {
        width: -webkit-fill-available;
    }

    .col-sm-7.col-12.position-relative .green-border .d-flex.mt-4 .header-title * {
        font-family: mainFont !important;
    }

    .col-sm-7.col-12.position-relative .green-border .d-flex.mt-4 .header-title img {
        max-width: 100%;
    }

    .btn-not-fill-custom.selected {
        background: #fff;
        color: var(--main);
        border-color: transparent;
    }

    .colse-l-t {
        position: absolute;
        left: 10px;
        top: 20px;
        cursor: pointer;
    }

    .w-200px {
        width: 200px !important;
    }

    .mt--2-7 {
        margin-top: -1.7rem;
    }

    .child-over-hidden > div > div {
        overflow: unset !important;
    }

    .t-l-0 {
        top: 0 !important;
        left: 0 !important;
    }

    .r-4 {
        right: 4px;
    }

    .top--6 {
        position: relative;
        top: 0;
    }

    .subject-container h3 {
        font-size: 16px;
    }

    .more-btn-not-fill {
        font-size: 14px !important;
    }

    #sticky-after-visited .question-container-sec.sticky-on-this .py-0 .px-sm-5.px-0.mx-sm-5.mx-0 {
        padding-top: 0;
        height: 118px;
        overflow: hidden;
    }

    #sticky-after-visited .question-container-sec.sticky-on-this .py-0 .px-sm-5.px-0.mx-sm-5.mx-0 {
        padding-top: 1px;
        height: 118px;
        overflow: hidden;
    }

    .question-container-sec.sticky-on-this {
        top: 0;
        box-shadow: 0 2px 24px #00000078;
    }

    .total-items {
        font-size: 12px;
    }

    .total-items {
        top: -1px;
    }

    .body-modal-old {
        left: 20px;
        right: 20px;
        padding: 0 20px 20px;
    }

    .phone-max-w-160px {
        max-width: 200px;
        margin: 0 auto;
    }

    .f-12 {
        font-size: 15px;
        color: #ffffffde;
    }

    .phone-inline {
        display: inline-block !important;
        font-size: 16px;
        font-weight: unset;
    }

    .title-container-2 h2 {
        font-size: 16px;
    }

    .phone-mt-0-imp {
        margin-top: 0 !important;
    }

    .btn-not-fill {
        font-size: 15px;
    }

    .login-is-rew h2 {
        font-size: 18px;
    }

    .login-is-rew small {
        font-size: 16px;
    }

    .mt-custom-s {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .color-ff-imp {
        color: #fff !important;
    }

    .change-to-book .swiper  .swiper-wrapper .swiper-slide {
        width: 150px !important;
    }

    .change-to-book .swiper .swiper-wrapper .swiper-slide > div {
        height: 200px;
    }

    .change-to-book .swiper .swiper-wrapper .swiper-slide img {
        height: 218px;
    }

    .change-to-book .swiper {
        height: 345px;
    }

    .title-container-sub h2 {
        font-size: 16px;
    }

    .all-book-sub-container {
        flex-wrap: nowrap;
        gap: 5px;
        overflow-x: scroll;
        padding: 0 1rem 0 2rem;
    }

    .like-and-bookmark.justify-content-end.gap-2.new-phone-l {
        left: 0 !important;
        justify-content: left !important;
    }

    .all-book-sub-container a {
        margin-left: 5px;
    }

    .left-dwn {
        position: absolute;
        left: 0;
        top: -4px;
    }

    .phone-w-un {
        width: unset !important;
    }

    :where(.css-dev-only-do-not-override-12upa3x).ant-select-dropdown {
        z-index: 99999999999999 !important;
    }

    .body-modal-old {
        margin: 0;
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;
    }

    .image-to-top-container {
        height: 102px;
    }

    .select-by-filter.d-flex.align-items-center.c-pointer.h-100 {
        max-width: 178px;
    }

    .slider-module .swiper .swiper-wrapper .swiper-slide:after {
        height: 85px;
        background: linear-gradient(0deg, rgb(0 0 0 / 82%) 28%, rgba(0, 212, 255, 0) 100%);
    }


    .gsc-search-button-v2 svg {
        fill: transparent !important;
        background: url(./assets/svg/search-icon.svg) no-repeat center;
        background-size: 22px;
        width: 21px;
        height: 20px;
    }

    .gsc-control-cse.gsc-control-cse-en {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .gsib_a input {
        background: transparent !important;
    }

    .search-container-phone > div {
        background: transparent;
        border-color: transparent !important;
        width: 100%;
    }

    .search-container-phone > div > div {
        padding: 0 !important;
        background: transparent !important;
        border-color: transparent !important;
    }

    .gsc-search-button.gsc-search-button-v2:visited,
    .gsc-search-button.gsc-search-button-v2:checked,
    .gsc-search-button.gsc-search-button-v2:hover,
    .gsc-search-button.gsc-search-button-v2 {
        border-color: transparent !important;
    }

    .gsc-input-box {
        padding: 0;
        background: transparent !important;
        border: transparent !important;
    }

    .gsc-input-box {
        padding: 0 !important;
    }

    .gstl_51.gssb_c {
        display: none !important;
    }

    .gsib_b {
        display: none;
    }

    .phone-mb-30 {
        margin-bottom: 30px;
    }

    .hidden-book {
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
    }

    .top-header.new-header {
        height: 145px;
    }
}